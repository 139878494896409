// 生成时间：2024/8/29 13:19:52
/* eslint-disable */
import axios from 'axios'
import qs from 'qs'
let domain = ''
export const getDomain = () => {
  return domain
}
export const setDomain = ($domain) => {
  domain = $domain
}
export const request = (method, url, body, queryParameters, form, config = {}) => {
  method = method.toLowerCase()
  let keys = Object.keys(queryParameters)
  let queryUrl = url
  if (keys.length > 0) {
    queryUrl = url + '?' + qs.stringify(queryParameters)
  }
  // let queryUrl = url+(keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
  if (body) {
    return axios[method](queryUrl, body, config)
  } else if (method === 'get') {
    return axios[method](queryUrl, {
      params: form,
      ...config
    })
  } else {
    return axios[method](queryUrl, qs.stringify(form), config)
  }
}
/*==========================================================
 *                    用户服务系统接口文档
 ==========================================================*/
/**
 * 预约挂号记录
 * request: bizAppointmentOrderList
 * formData: bizAppointmentOrderListFormData
 * url: bizAppointmentOrderList_URL
 * method: bizAppointmentOrderList_TYPE
 * raw_url: bizAppointmentOrderList_RAW_URL
 * @param page - 
 * @param rows - 
 * @param sort - 
 * @param order - 
 */
export const bizAppointmentOrderList = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/business/appointment-order'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const bizAppointmentOrderListFormData = function(formData) {
  const url = bizAppointmentOrderList_RAW_URL()
  const method = bizAppointmentOrderList_TYPE()
  return axios[method](url, formData)
}
export const bizAppointmentOrderList_RAW_URL = function() {
  return '/business/appointment-order'
}
export const bizAppointmentOrderList_TYPE = function() {
  return 'get'
}
export const bizAppointmentOrderList_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/business/appointment-order'
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 新增预约挂号
 * request: bizAppointmentOrderAdd
 * formData: bizAppointmentOrderAddFormData
 * url: bizAppointmentOrderAdd_URL
 * method: bizAppointmentOrderAdd_TYPE
 * raw_url: bizAppointmentOrderAdd_RAW_URL
 * @param targetSiteCode - 所选医院站点编码
 * @param workSchedulingId - 排班ID
 * @param sitePatientId - 站点患者ID
 * @param startTime - 开始时间
 * @param endTime - 结束时间
 * @param firstVisit - 是否初诊
 */
export const bizAppointmentOrderAdd = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/business/appointment-order'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['targetSiteCode'] !== undefined) {
    form['targetSiteCode'] = parameters['targetSiteCode']
  }
  if (parameters['targetSiteCode'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: targetSiteCode'))
  }
  if (parameters['workSchedulingId'] !== undefined) {
    form['workSchedulingId'] = parameters['workSchedulingId']
  }
  if (parameters['workSchedulingId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: workSchedulingId'))
  }
  if (parameters['sitePatientId'] !== undefined) {
    form['sitePatientId'] = parameters['sitePatientId']
  }
  if (parameters['sitePatientId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: sitePatientId'))
  }
  if (parameters['startTime'] !== undefined) {
    form['startTime'] = parameters['startTime']
  }
  if (parameters['startTime'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: startTime'))
  }
  if (parameters['endTime'] !== undefined) {
    form['endTime'] = parameters['endTime']
  }
  if (parameters['endTime'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: endTime'))
  }
  if (parameters['firstVisit'] !== undefined) {
    form['firstVisit'] = parameters['firstVisit']
  }
  if (parameters['firstVisit'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: firstVisit'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const bizAppointmentOrderAddFormData = function(formData) {
  const url = bizAppointmentOrderAdd_RAW_URL()
  const method = bizAppointmentOrderAdd_TYPE()
  return axios[method](url, formData)
}
export const bizAppointmentOrderAdd_RAW_URL = function() {
  return '/business/appointment-order'
}
export const bizAppointmentOrderAdd_TYPE = function() {
  return 'post'
}
export const bizAppointmentOrderAdd_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/business/appointment-order'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 预约挂号详情
 * request: bizAppointmentOrderFind
 * formData: bizAppointmentOrderFindFormData
 * url: bizAppointmentOrderFind_URL
 * method: bizAppointmentOrderFind_TYPE
 * raw_url: bizAppointmentOrderFind_RAW_URL
 * @param id - id
 */
export const bizAppointmentOrderFind = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/business/appointment-order/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const bizAppointmentOrderFindFormData = function(formData) {
  const url = bizAppointmentOrderFind_RAW_URL()
  const method = bizAppointmentOrderFind_TYPE()
  return axios[method](url, formData)
}
export const bizAppointmentOrderFind_RAW_URL = function() {
  return '/business/appointment-order/{id}'
}
export const bizAppointmentOrderFind_TYPE = function() {
  return 'get'
}
export const bizAppointmentOrderFind_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/business/appointment-order/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 取消预约挂号
 * request: bizAppointmentOrderCancel
 * formData: bizAppointmentOrderCancelFormData
 * url: bizAppointmentOrderCancel_URL
 * method: bizAppointmentOrderCancel_TYPE
 * raw_url: bizAppointmentOrderCancel_RAW_URL
 * @param id - id
 */
export const bizAppointmentOrderCancel = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/business/appointment-order/{id}/cancel'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const bizAppointmentOrderCancelFormData = function(formData) {
  const url = bizAppointmentOrderCancel_RAW_URL()
  const method = bizAppointmentOrderCancel_TYPE()
  return axios[method](url, formData)
}
export const bizAppointmentOrderCancel_RAW_URL = function() {
  return '/business/appointment-order/{id}/cancel'
}
export const bizAppointmentOrderCancel_TYPE = function() {
  return 'put'
}
export const bizAppointmentOrderCancel_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/business/appointment-order/{id}/cancel'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 可预约地址
 * request: bizAppointmentAddressList
 * formData: bizAppointmentAddressListFormData
 * url: bizAppointmentAddressList_URL
 * method: bizAppointmentAddressList_TYPE
 * raw_url: bizAppointmentAddressList_RAW_URL
 * @param screeningPlanId - screeningPlanId
 */
export const bizAppointmentAddressList = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/business/appointment/address'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['screeningPlanId'] !== undefined) {
    queryParameters['screeningPlanId'] = parameters['screeningPlanId']
  }
  if (parameters['screeningPlanId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningPlanId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const bizAppointmentAddressListFormData = function(formData) {
  const url = bizAppointmentAddressList_RAW_URL()
  const method = bizAppointmentAddressList_TYPE()
  return axios[method](url, formData)
}
export const bizAppointmentAddressList_RAW_URL = function() {
  return '/business/appointment/address'
}
export const bizAppointmentAddressList_TYPE = function() {
  return 'get'
}
export const bizAppointmentAddressList_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/business/appointment/address'
  if (parameters['screeningPlanId'] !== undefined) {
    queryParameters['screeningPlanId'] = parameters['screeningPlanId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 预约登记列表
 * request: bizAppointmentListRecord
 * formData: bizAppointmentListRecordFormData
 * url: bizAppointmentListRecord_URL
 * method: bizAppointmentListRecord_TYPE
 * raw_url: bizAppointmentListRecord_RAW_URL
 */
export const bizAppointmentListRecord = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/business/appointment/record'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const bizAppointmentListRecordFormData = function(formData) {
  const url = bizAppointmentListRecord_RAW_URL()
  const method = bizAppointmentListRecord_TYPE()
  return axios[method](url, formData)
}
export const bizAppointmentListRecord_RAW_URL = function() {
  return '/business/appointment/record'
}
export const bizAppointmentListRecord_TYPE = function() {
  return 'get'
}
export const bizAppointmentListRecord_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/business/appointment/record'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 预约登记
 * request: bizAppointmentAddRecord
 * formData: bizAppointmentAddRecordFormData
 * url: bizAppointmentAddRecord_URL
 * method: bizAppointmentAddRecord_TYPE
 * raw_url: bizAppointmentAddRecord_RAW_URL
 * @param screeningPlanStudentId - 
 * @param appointmentAddressId - 
 * @param appointmentAddressName - 
 * @param name - 
 * @param mobile - 
 * @param bookingDate - 
 * @param remark - 
 */
export const bizAppointmentAddRecord = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/business/appointment/record'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['screeningPlanStudentId'] !== undefined) {
    form['screeningPlanStudentId'] = parameters['screeningPlanStudentId']
  }
  if (parameters['screeningPlanStudentId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningPlanStudentId'))
  }
  if (parameters['appointmentAddressId'] !== undefined) {
    form['appointmentAddressId'] = parameters['appointmentAddressId']
  }
  if (parameters['appointmentAddressId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: appointmentAddressId'))
  }
  if (parameters['appointmentAddressName'] !== undefined) {
    form['appointmentAddressName'] = parameters['appointmentAddressName']
  }
  if (parameters['appointmentAddressName'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: appointmentAddressName'))
  }
  if (parameters['name'] !== undefined) {
    form['name'] = parameters['name']
  }
  if (parameters['name'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: name'))
  }
  if (parameters['mobile'] !== undefined) {
    form['mobile'] = parameters['mobile']
  }
  if (parameters['mobile'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: mobile'))
  }
  if (parameters['bookingDate'] !== undefined) {
    form['bookingDate'] = parameters['bookingDate']
  }
  if (parameters['bookingDate'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: bookingDate'))
  }
  if (parameters['remark'] !== undefined) {
    form['remark'] = parameters['remark']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const bizAppointmentAddRecordFormData = function(formData) {
  const url = bizAppointmentAddRecord_RAW_URL()
  const method = bizAppointmentAddRecord_TYPE()
  return axios[method](url, formData)
}
export const bizAppointmentAddRecord_RAW_URL = function() {
  return '/business/appointment/record'
}
export const bizAppointmentAddRecord_TYPE = function() {
  return 'post'
}
export const bizAppointmentAddRecord_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/business/appointment/record'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 数据用户列表
 * request: bizDataUserList
 * formData: bizDataUserListFormData
 * url: bizDataUserList_URL
 * method: bizDataUserList_TYPE
 * raw_url: bizDataUserList_RAW_URL
 */
export const bizDataUserList = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/business/data-user'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const bizDataUserListFormData = function(formData) {
  const url = bizDataUserList_RAW_URL()
  const method = bizDataUserList_TYPE()
  return axios[method](url, formData)
}
export const bizDataUserList_RAW_URL = function() {
  return '/business/data-user'
}
export const bizDataUserList_TYPE = function() {
  return 'get'
}
export const bizDataUserList_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/business/data-user'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 添加数据用户
 * request: bizDataUserAdd
 * formData: bizDataUserAddFormData
 * url: bizDataUserAdd_URL
 * method: bizDataUserAdd_TYPE
 * raw_url: bizDataUserAdd_RAW_URL
 * @param organizationId - 
 * @param name - 
 * @param sex - 
 * @param birth - 
 * @param idCard - 
 * @param mobile - 
 */
export const bizDataUserAdd = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/business/data-user'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['organizationId'] !== undefined) {
    form['organizationId'] = parameters['organizationId']
  }
  if (parameters['name'] !== undefined) {
    form['name'] = parameters['name']
  }
  if (parameters['name'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: name'))
  }
  if (parameters['sex'] !== undefined) {
    form['sex'] = parameters['sex']
  }
  if (parameters['sex'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: sex'))
  }
  if (parameters['birth'] !== undefined) {
    form['birth'] = parameters['birth']
  }
  if (parameters['birth'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: birth'))
  }
  if (parameters['idCard'] !== undefined) {
    form['idCard'] = parameters['idCard']
  }
  if (parameters['mobile'] !== undefined) {
    form['mobile'] = parameters['mobile']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const bizDataUserAddFormData = function(formData) {
  const url = bizDataUserAdd_RAW_URL()
  const method = bizDataUserAdd_TYPE()
  return axios[method](url, formData)
}
export const bizDataUserAdd_RAW_URL = function() {
  return '/business/data-user'
}
export const bizDataUserAdd_TYPE = function() {
  return 'post'
}
export const bizDataUserAdd_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/business/data-user'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 绑定数据用户
 * request: bizDataUserBinding
 * formData: bizDataUserBindingFormData
 * url: bizDataUserBinding_URL
 * method: bizDataUserBinding_TYPE
 * raw_url: bizDataUserBinding_RAW_URL
 * @param enterpriseId - 企业ID
 * @param dataUserId - 数据用户ID
 * @param name - 数据用户姓名
 */
export const bizDataUserBinding = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/business/data-user/binding'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['enterpriseId'] !== undefined) {
    form['enterpriseId'] = parameters['enterpriseId']
  }
  if (parameters['enterpriseId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: enterpriseId'))
  }
  if (parameters['dataUserId'] !== undefined) {
    form['dataUserId'] = parameters['dataUserId']
  }
  if (parameters['dataUserId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: dataUserId'))
  }
  if (parameters['name'] !== undefined) {
    form['name'] = parameters['name']
  }
  if (parameters['name'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: name'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const bizDataUserBindingFormData = function(formData) {
  const url = bizDataUserBinding_RAW_URL()
  const method = bizDataUserBinding_TYPE()
  return axios[method](url, formData)
}
export const bizDataUserBinding_RAW_URL = function() {
  return '/business/data-user/binding'
}
export const bizDataUserBinding_TYPE = function() {
  return 'post'
}
export const bizDataUserBinding_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/business/data-user/binding'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 通过扫码和身份证查询数据用户
 * request: bizDataUserByKeyword
 * formData: bizDataUserByKeywordFormData
 * url: bizDataUserByKeyword_URL
 * method: bizDataUserByKeyword_TYPE
 * raw_url: bizDataUserByKeyword_RAW_URL
 * @param dataUserId - 
 * @param name - 
 * @param idCard - 
 */
export const bizDataUserByKeyword = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/business/data-user/by-keyword'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['dataUserId'] !== undefined) {
    queryParameters['dataUserId'] = parameters['dataUserId']
  }
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['idCard'] !== undefined) {
    queryParameters['idCard'] = parameters['idCard']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const bizDataUserByKeywordFormData = function(formData) {
  const url = bizDataUserByKeyword_RAW_URL()
  const method = bizDataUserByKeyword_TYPE()
  return axios[method](url, formData)
}
export const bizDataUserByKeyword_RAW_URL = function() {
  return '/business/data-user/by-keyword'
}
export const bizDataUserByKeyword_TYPE = function() {
  return 'get'
}
export const bizDataUserByKeyword_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/business/data-user/by-keyword'
  if (parameters['dataUserId'] !== undefined) {
    queryParameters['dataUserId'] = parameters['dataUserId']
  }
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['idCard'] !== undefined) {
    queryParameters['idCard'] = parameters['idCard']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 根据数据文档查看数据文档模板
 * request: bizDataUserFindDataFileTpl
 * formData: bizDataUserFindDataFileTplFormData
 * url: bizDataUserFindDataFileTpl_URL
 * method: bizDataUserFindDataFileTpl_TYPE
 * raw_url: bizDataUserFindDataFileTpl_RAW_URL
 * @param fileId - fileId
 */
export const bizDataUserFindDataFileTpl = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/business/data-user/data-file-tpl/{fileId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{fileId}', `${parameters['fileId']}`)
  if (parameters['fileId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: fileId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const bizDataUserFindDataFileTplFormData = function(formData) {
  const url = bizDataUserFindDataFileTpl_RAW_URL()
  const method = bizDataUserFindDataFileTpl_TYPE()
  return axios[method](url, formData)
}
export const bizDataUserFindDataFileTpl_RAW_URL = function() {
  return '/business/data-user/data-file-tpl/{fileId}'
}
export const bizDataUserFindDataFileTpl_TYPE = function() {
  return 'get'
}
export const bizDataUserFindDataFileTpl_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/business/data-user/data-file-tpl/{fileId}'
  path = path.replace('{fileId}', `${parameters['fileId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 查看数据用户
 * request: bizDataUserFind
 * formData: bizDataUserFindFormData
 * url: bizDataUserFind_URL
 * method: bizDataUserFind_TYPE
 * raw_url: bizDataUserFind_RAW_URL
 * @param dataUserId - dataUserId
 */
export const bizDataUserFind = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/business/data-user/{dataUserId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{dataUserId}', `${parameters['dataUserId']}`)
  if (parameters['dataUserId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: dataUserId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const bizDataUserFindFormData = function(formData) {
  const url = bizDataUserFind_RAW_URL()
  const method = bizDataUserFind_TYPE()
  return axios[method](url, formData)
}
export const bizDataUserFind_RAW_URL = function() {
  return '/business/data-user/{dataUserId}'
}
export const bizDataUserFind_TYPE = function() {
  return 'get'
}
export const bizDataUserFind_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/business/data-user/{dataUserId}'
  path = path.replace('{dataUserId}', `${parameters['dataUserId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 修改数据用户
 * request: bizDataUserUpdate
 * formData: bizDataUserUpdateFormData
 * url: bizDataUserUpdate_URL
 * method: bizDataUserUpdate_TYPE
 * raw_url: bizDataUserUpdate_RAW_URL
 * @param dataUserId - dataUserId
 * @param name - 
 * @param sex - 
 * @param birth - 
 * @param idCard - 
 * @param mobile - 
 */
export const bizDataUserUpdate = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/business/data-user/{dataUserId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{dataUserId}', `${parameters['dataUserId']}`)
  if (parameters['dataUserId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: dataUserId'))
  }
  if (parameters['name'] !== undefined) {
    form['name'] = parameters['name']
  }
  if (parameters['name'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: name'))
  }
  if (parameters['sex'] !== undefined) {
    form['sex'] = parameters['sex']
  }
  if (parameters['sex'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: sex'))
  }
  if (parameters['birth'] !== undefined) {
    form['birth'] = parameters['birth']
  }
  if (parameters['birth'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: birth'))
  }
  if (parameters['idCard'] !== undefined) {
    form['idCard'] = parameters['idCard']
  }
  if (parameters['mobile'] !== undefined) {
    form['mobile'] = parameters['mobile']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const bizDataUserUpdateFormData = function(formData) {
  const url = bizDataUserUpdate_RAW_URL()
  const method = bizDataUserUpdate_TYPE()
  return axios[method](url, formData)
}
export const bizDataUserUpdate_RAW_URL = function() {
  return '/business/data-user/{dataUserId}'
}
export const bizDataUserUpdate_TYPE = function() {
  return 'put'
}
export const bizDataUserUpdate_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/business/data-user/{dataUserId}'
  path = path.replace('{dataUserId}', `${parameters['dataUserId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 数据用户文档列表
 * request: bizDataUserDataFileList
 * formData: bizDataUserDataFileListFormData
 * url: bizDataUserDataFileList_URL
 * method: bizDataUserDataFileList_TYPE
 * raw_url: bizDataUserDataFileList_RAW_URL
 * @param dataUserId - dataUserId
 * @param dataFolderId - 
 * @param dataFileTplId - 
 * @param page - 
 * @param rows - 
 * @param sort - 
 * @param order - 
 */
export const bizDataUserDataFileList = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/business/data-user/{dataUserId}/data-file'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{dataUserId}', `${parameters['dataUserId']}`)
  if (parameters['dataUserId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: dataUserId'))
  }
  if (parameters['dataFolderId'] !== undefined) {
    queryParameters['dataFolderId'] = parameters['dataFolderId']
  }
  if (parameters['dataFileTplId'] !== undefined) {
    queryParameters['dataFileTplId'] = parameters['dataFileTplId']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const bizDataUserDataFileListFormData = function(formData) {
  const url = bizDataUserDataFileList_RAW_URL()
  const method = bizDataUserDataFileList_TYPE()
  return axios[method](url, formData)
}
export const bizDataUserDataFileList_RAW_URL = function() {
  return '/business/data-user/{dataUserId}/data-file'
}
export const bizDataUserDataFileList_TYPE = function() {
  return 'get'
}
export const bizDataUserDataFileList_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/business/data-user/{dataUserId}/data-file'
  path = path.replace('{dataUserId}', `${parameters['dataUserId']}`)
  if (parameters['dataFolderId'] !== undefined) {
    queryParameters['dataFolderId'] = parameters['dataFolderId']
  }
  if (parameters['dataFileTplId'] !== undefined) {
    queryParameters['dataFileTplId'] = parameters['dataFileTplId']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 科室列表
 * request: bizDepartmentList
 * formData: bizDepartmentListFormData
 * url: bizDepartmentList_URL
 * method: bizDepartmentList_TYPE
 * raw_url: bizDepartmentList_RAW_URL
 * @param targetSiteCode - 所选医院站点编码
 */
export const bizDepartmentList = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/business/department'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['targetSiteCode'] !== undefined) {
    queryParameters['targetSiteCode'] = parameters['targetSiteCode']
  }
  if (parameters['targetSiteCode'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: targetSiteCode'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const bizDepartmentListFormData = function(formData) {
  const url = bizDepartmentList_RAW_URL()
  const method = bizDepartmentList_TYPE()
  return axios[method](url, formData)
}
export const bizDepartmentList_RAW_URL = function() {
  return '/business/department'
}
export const bizDepartmentList_TYPE = function() {
  return 'get'
}
export const bizDepartmentList_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/business/department'
  if (parameters['targetSiteCode'] !== undefined) {
    queryParameters['targetSiteCode'] = parameters['targetSiteCode']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 医院列表
 * request: bizHospitalList
 * formData: bizHospitalListFormData
 * url: bizHospitalList_URL
 * method: bizHospitalList_TYPE
 * raw_url: bizHospitalList_RAW_URL
 */
export const bizHospitalList = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/business/hospital'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const bizHospitalListFormData = function(formData) {
  const url = bizHospitalList_RAW_URL()
  const method = bizHospitalList_TYPE()
  return axios[method](url, formData)
}
export const bizHospitalList_RAW_URL = function() {
  return '/business/hospital'
}
export const bizHospitalList_TYPE = function() {
  return 'get'
}
export const bizHospitalList_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/business/hospital'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 医院信息列表
 * request: bizHospitalInformationList
 * formData: bizHospitalInformationListFormData
 * url: bizHospitalInformationList_URL
 * method: bizHospitalInformationList_TYPE
 * raw_url: bizHospitalInformationList_RAW_URL
 * @param screeningPlanId - screeningPlanId
 */
export const bizHospitalInformationList = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/business/hospital-information'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['screeningPlanId'] !== undefined) {
    queryParameters['screeningPlanId'] = parameters['screeningPlanId']
  }
  if (parameters['screeningPlanId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningPlanId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const bizHospitalInformationListFormData = function(formData) {
  const url = bizHospitalInformationList_RAW_URL()
  const method = bizHospitalInformationList_TYPE()
  return axios[method](url, formData)
}
export const bizHospitalInformationList_RAW_URL = function() {
  return '/business/hospital-information'
}
export const bizHospitalInformationList_TYPE = function() {
  return 'get'
}
export const bizHospitalInformationList_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/business/hospital-information'
  if (parameters['screeningPlanId'] !== undefined) {
    queryParameters['screeningPlanId'] = parameters['screeningPlanId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 医院详情(根据站点编码)
 * request: bizHospitalFindBySiteCode
 * formData: bizHospitalFindBySiteCodeFormData
 * url: bizHospitalFindBySiteCode_URL
 * method: bizHospitalFindBySiteCode_TYPE
 * raw_url: bizHospitalFindBySiteCode_RAW_URL
 * @param targetSiteCode - targetSiteCode
 */
export const bizHospitalFindBySiteCode = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/business/hospital/by-site-code'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['targetSiteCode'] !== undefined) {
    queryParameters['targetSiteCode'] = parameters['targetSiteCode']
  }
  if (parameters['targetSiteCode'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: targetSiteCode'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const bizHospitalFindBySiteCodeFormData = function(formData) {
  const url = bizHospitalFindBySiteCode_RAW_URL()
  const method = bizHospitalFindBySiteCode_TYPE()
  return axios[method](url, formData)
}
export const bizHospitalFindBySiteCode_RAW_URL = function() {
  return '/business/hospital/by-site-code'
}
export const bizHospitalFindBySiteCode_TYPE = function() {
  return 'get'
}
export const bizHospitalFindBySiteCode_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/business/hospital/by-site-code'
  if (parameters['targetSiteCode'] !== undefined) {
    queryParameters['targetSiteCode'] = parameters['targetSiteCode']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 医院详情
 * request: bizHospitalFind
 * formData: bizHospitalFindFormData
 * url: bizHospitalFind_URL
 * method: bizHospitalFind_TYPE
 * raw_url: bizHospitalFind_RAW_URL
 * @param id - id
 */
export const bizHospitalFind = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/business/hospital/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const bizHospitalFindFormData = function(formData) {
  const url = bizHospitalFind_RAW_URL()
  const method = bizHospitalFind_TYPE()
  return axios[method](url, formData)
}
export const bizHospitalFind_RAW_URL = function() {
  return '/business/hospital/{id}'
}
export const bizHospitalFind_TYPE = function() {
  return 'get'
}
export const bizHospitalFind_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/business/hospital/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Hello
 * request: bizMainHello
 * formData: bizMainHelloFormData
 * url: bizMainHello_URL
 * method: bizMainHello_TYPE
 * raw_url: bizMainHello_RAW_URL
 */
export const bizMainHello = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/business/main/hello'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const bizMainHelloFormData = function(formData) {
  const url = bizMainHello_RAW_URL()
  const method = bizMainHello_TYPE()
  return axios[method](url, formData)
}
export const bizMainHello_RAW_URL = function() {
  return '/business/main/hello'
}
export const bizMainHello_TYPE = function() {
  return 'get'
}
export const bizMainHello_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/business/main/hello'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 微信网页服务配置
 * request: bizMainWxWebConfig
 * formData: bizMainWxWebConfigFormData
 * url: bizMainWxWebConfig_URL
 * method: bizMainWxWebConfig_TYPE
 * raw_url: bizMainWxWebConfig_RAW_URL
 */
export const bizMainWxWebConfig = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/business/main/wx-web-config'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const bizMainWxWebConfigFormData = function(formData) {
  const url = bizMainWxWebConfig_RAW_URL()
  const method = bizMainWxWebConfig_TYPE()
  return axios[method](url, formData)
}
export const bizMainWxWebConfig_RAW_URL = function() {
  return '/business/main/wx-web-config'
}
export const bizMainWxWebConfig_TYPE = function() {
  return 'get'
}
export const bizMainWxWebConfig_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/business/main/wx-web-config'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 我的检查报告列表
 * request: bizMedicalReportList
 * formData: bizMedicalReportListFormData
 * url: bizMedicalReportList_URL
 * method: bizMedicalReportList_TYPE
 * raw_url: bizMedicalReportList_RAW_URL
 * @param sitePatientId - 站点患者ID(选全部时不传)
 * @param page - 
 * @param rows - 
 * @param sort - 
 * @param order - 
 */
export const bizMedicalReportList = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/business/medical-report'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['sitePatientId'] !== undefined) {
    queryParameters['sitePatientId'] = parameters['sitePatientId']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const bizMedicalReportListFormData = function(formData) {
  const url = bizMedicalReportList_RAW_URL()
  const method = bizMedicalReportList_TYPE()
  return axios[method](url, formData)
}
export const bizMedicalReportList_RAW_URL = function() {
  return '/business/medical-report'
}
export const bizMedicalReportList_TYPE = function() {
  return 'get'
}
export const bizMedicalReportList_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/business/medical-report'
  if (parameters['sitePatientId'] !== undefined) {
    queryParameters['sitePatientId'] = parameters['sitePatientId']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 查看检查报告图片
 * request: bizMedicalReportFindPic
 * formData: bizMedicalReportFindPicFormData
 * url: bizMedicalReportFindPic_URL
 * method: bizMedicalReportFindPic_TYPE
 * raw_url: bizMedicalReportFindPic_RAW_URL
 * @param reportId - reportId
 */
export const bizMedicalReportFindPic = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/business/medical-report/{reportId}/pic'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{reportId}', `${parameters['reportId']}`)
  if (parameters['reportId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: reportId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const bizMedicalReportFindPicFormData = function(formData) {
  const url = bizMedicalReportFindPic_RAW_URL()
  const method = bizMedicalReportFindPic_TYPE()
  return axios[method](url, formData)
}
export const bizMedicalReportFindPic_RAW_URL = function() {
  return '/business/medical-report/{reportId}/pic'
}
export const bizMedicalReportFindPic_TYPE = function() {
  return 'get'
}
export const bizMedicalReportFindPic_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/business/medical-report/{reportId}/pic'
  path = path.replace('{reportId}', `${parameters['reportId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 新增调查问卷
 * request: bizQuestionnaireAdd
 * formData: bizQuestionnaireAddFormData
 * url: bizQuestionnaireAdd_URL
 * method: bizQuestionnaireAdd_TYPE
 * raw_url: bizQuestionnaireAdd_RAW_URL
 * @param screeningPlanStudentId - 筛查计划学生ID
 * @param dadLeftVision - 父亲左眼裸眼视力
 * @param dadRightVision - 父亲眼裸眼视力
 * @param dadLeftSE - 父亲左眼等效球镜
 * @param dadRightSE - 父亲右眼等效球镜
 * @param momLeftVision - 母亲左眼裸眼视力
 * @param momRightVision - 母亲右眼裸眼视力
 * @param momLeftSE - 母亲左眼等效球镜
 * @param momRightSE - 母亲右眼等效球镜
 * @param a1 - 在家对数照明(单选)
 * @param a2 - 电子视频阅读时间(单选)(分钟数)
 * @param a3 - 饮食结构(单选)
 */
export const bizQuestionnaireAdd = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/business/questionnaire'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['screeningPlanStudentId'] !== undefined) {
    form['screeningPlanStudentId'] = parameters['screeningPlanStudentId']
  }
  if (parameters['screeningPlanStudentId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningPlanStudentId'))
  }
  if (parameters['dadLeftVision'] !== undefined) {
    form['dadLeftVision'] = parameters['dadLeftVision']
  }
  if (parameters['dadLeftVision'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: dadLeftVision'))
  }
  if (parameters['dadRightVision'] !== undefined) {
    form['dadRightVision'] = parameters['dadRightVision']
  }
  if (parameters['dadRightVision'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: dadRightVision'))
  }
  if (parameters['dadLeftSE'] !== undefined) {
    form['dadLeftSE'] = parameters['dadLeftSE']
  }
  if (parameters['dadLeftSE'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: dadLeftSe'))
  }
  if (parameters['dadRightSE'] !== undefined) {
    form['dadRightSE'] = parameters['dadRightSE']
  }
  if (parameters['dadRightSE'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: dadRightSe'))
  }
  if (parameters['momLeftVision'] !== undefined) {
    form['momLeftVision'] = parameters['momLeftVision']
  }
  if (parameters['momLeftVision'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: momLeftVision'))
  }
  if (parameters['momRightVision'] !== undefined) {
    form['momRightVision'] = parameters['momRightVision']
  }
  if (parameters['momRightVision'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: momRightVision'))
  }
  if (parameters['momLeftSE'] !== undefined) {
    form['momLeftSE'] = parameters['momLeftSE']
  }
  if (parameters['momLeftSE'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: momLeftSe'))
  }
  if (parameters['momRightSE'] !== undefined) {
    form['momRightSE'] = parameters['momRightSE']
  }
  if (parameters['momRightSE'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: momRightSe'))
  }
  if (parameters['a1'] !== undefined) {
    form['a1'] = parameters['a1']
  }
  if (parameters['a1'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a1'))
  }
  if (parameters['a2'] !== undefined) {
    form['a2'] = parameters['a2']
  }
  if (parameters['a2'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a2'))
  }
  if (parameters['a3'] !== undefined) {
    form['a3'] = parameters['a3']
  }
  if (parameters['a3'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a3'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const bizQuestionnaireAddFormData = function(formData) {
  const url = bizQuestionnaireAdd_RAW_URL()
  const method = bizQuestionnaireAdd_TYPE()
  return axios[method](url, formData)
}
export const bizQuestionnaireAdd_RAW_URL = function() {
  return '/business/questionnaire'
}
export const bizQuestionnaireAdd_TYPE = function() {
  return 'post'
}
export const bizQuestionnaireAdd_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/business/questionnaire'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 身份证和名字查询学生
 * request: bizStudentByIdCardAndName
 * formData: bizStudentByIdCardAndNameFormData
 * url: bizStudentByIdCardAndName_URL
 * method: bizStudentByIdCardAndName_TYPE
 * raw_url: bizStudentByIdCardAndName_RAW_URL
 * @param siteCode - siteCode
 * @param idCard - 
 * @param name - 
 */
export const bizStudentByIdCardAndName = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/business/questionnaire/by-student-idCard/{planId}'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['siteCode'] !== undefined) {
    queryParameters['siteCode'] = parameters['siteCode']
  }
  if (parameters['siteCode'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: siteCode'))
  }
  if (parameters['idCard'] !== undefined) {
    queryParameters['idCard'] = parameters['idCard']
  }
  if (parameters['idCard'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: idCard'))
  }
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['name'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: name'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const bizStudentByIdCardAndNameFormData = function(formData) {
  const url = bizStudentByIdCardAndName_RAW_URL()
  const method = bizStudentByIdCardAndName_TYPE()
  return axios[method](url, formData)
}
export const bizStudentByIdCardAndName_RAW_URL = function() {
  return '/business/questionnaire/by-student-idCard/{planId}'
}
export const bizStudentByIdCardAndName_TYPE = function() {
  return 'get'
}
export const bizStudentByIdCardAndName_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/business/questionnaire/by-student-idCard/{planId}'
  if (parameters['siteCode'] !== undefined) {
    queryParameters['siteCode'] = parameters['siteCode']
  }
  if (parameters['idCard'] !== undefined) {
    queryParameters['idCard'] = parameters['idCard']
  }
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 学校&年级&班级&名字查询学生
 * request: bizStudentBySchoolAndGradeAndClass
 * formData: bizStudentBySchoolAndGradeAndClassFormData
 * url: bizStudentBySchoolAndGradeAndClass_URL
 * method: bizStudentBySchoolAndGradeAndClass_TYPE
 * raw_url: bizStudentBySchoolAndGradeAndClass_RAW_URL
 * @param planId - planId
 * @param schoolId - 
 * @param gradeValue - 
 * @param classValue - 
 * @param name - 
 */
export const bizStudentBySchoolAndGradeAndClass = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/business/questionnaire/by-student-school-grade-class/{planId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{planId}', `${parameters['planId']}`)
  if (parameters['planId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: planId'))
  }
  if (parameters['schoolId'] !== undefined) {
    queryParameters['schoolId'] = parameters['schoolId']
  }
  if (parameters['schoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: schoolId'))
  }
  if (parameters['gradeValue'] !== undefined) {
    queryParameters['gradeValue'] = parameters['gradeValue']
  }
  if (parameters['gradeValue'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: gradeValue'))
  }
  if (parameters['classValue'] !== undefined) {
    queryParameters['classValue'] = parameters['classValue']
  }
  if (parameters['classValue'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: classValue'))
  }
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['name'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: name'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const bizStudentBySchoolAndGradeAndClassFormData = function(formData) {
  const url = bizStudentBySchoolAndGradeAndClass_RAW_URL()
  const method = bizStudentBySchoolAndGradeAndClass_TYPE()
  return axios[method](url, formData)
}
export const bizStudentBySchoolAndGradeAndClass_RAW_URL = function() {
  return '/business/questionnaire/by-student-school-grade-class/{planId}'
}
export const bizStudentBySchoolAndGradeAndClass_TYPE = function() {
  return 'get'
}
export const bizStudentBySchoolAndGradeAndClass_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/business/questionnaire/by-student-school-grade-class/{planId}'
  path = path.replace('{planId}', `${parameters['planId']}`)
  if (parameters['schoolId'] !== undefined) {
    queryParameters['schoolId'] = parameters['schoolId']
  }
  if (parameters['gradeValue'] !== undefined) {
    queryParameters['gradeValue'] = parameters['gradeValue']
  }
  if (parameters['classValue'] !== undefined) {
    queryParameters['classValue'] = parameters['classValue']
  }
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 通过问卷id查询儿童青少年近视调查问卷
 * request: bizQuestionnaireFindCamsq
 * formData: bizQuestionnaireFindCamsqFormData
 * url: bizQuestionnaireFindCamsq_URL
 * method: bizQuestionnaireFindCamsq_TYPE
 * raw_url: bizQuestionnaireFindCamsq_RAW_URL
 * @param camsqId - camsqId
 * @param siteCode - siteCode
 */
export const bizQuestionnaireFindCamsq = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/business/questionnaire/find-camsq/{camsqId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{camsqId}', `${parameters['camsqId']}`)
  if (parameters['camsqId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: camsqId'))
  }
  if (parameters['siteCode'] !== undefined) {
    queryParameters['siteCode'] = parameters['siteCode']
  }
  if (parameters['siteCode'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: siteCode'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const bizQuestionnaireFindCamsqFormData = function(formData) {
  const url = bizQuestionnaireFindCamsq_RAW_URL()
  const method = bizQuestionnaireFindCamsq_TYPE()
  return axios[method](url, formData)
}
export const bizQuestionnaireFindCamsq_RAW_URL = function() {
  return '/business/questionnaire/find-camsq/{camsqId}'
}
export const bizQuestionnaireFindCamsq_TYPE = function() {
  return 'get'
}
export const bizQuestionnaireFindCamsq_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/business/questionnaire/find-camsq/{camsqId}'
  path = path.replace('{camsqId}', `${parameters['camsqId']}`)
  if (parameters['siteCode'] !== undefined) {
    queryParameters['siteCode'] = parameters['siteCode']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 获取年级列表
 * request: bizFindGradeConfig
 * formData: bizFindGradeConfigFormData
 * url: bizFindGradeConfig_URL
 * method: bizFindGradeConfig_TYPE
 * raw_url: bizFindGradeConfig_RAW_URL
 * @param siteCode - siteCode
 */
export const bizFindGradeConfig = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/business/questionnaire/find-grade-config'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['siteCode'] !== undefined) {
    queryParameters['siteCode'] = parameters['siteCode']
  }
  if (parameters['siteCode'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: siteCode'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const bizFindGradeConfigFormData = function(formData) {
  const url = bizFindGradeConfig_RAW_URL()
  const method = bizFindGradeConfig_TYPE()
  return axios[method](url, formData)
}
export const bizFindGradeConfig_RAW_URL = function() {
  return '/business/questionnaire/find-grade-config'
}
export const bizFindGradeConfig_TYPE = function() {
  return 'get'
}
export const bizFindGradeConfig_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/business/questionnaire/find-grade-config'
  if (parameters['siteCode'] !== undefined) {
    queryParameters['siteCode'] = parameters['siteCode']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 保存儿童青少年近视调查问卷
 * request: bizQuestionnaireSaveCamsq
 * formData: bizQuestionnaireSaveCamsqFormData
 * url: bizQuestionnaireSaveCamsq_URL
 * method: bizQuestionnaireSaveCamsq_TYPE
 * raw_url: bizQuestionnaireSaveCamsq_RAW_URL
 * @param siteCode - siteCode
 * @param name - 姓名
 * @param idCard - 身份证
 * @param age - 年龄
 * @param sex - 性别
 * @param schoolName - 学校名字
 * @param code - 学号
 * @param gradeValue - 年级
 * @param className - 班级名称
 * @param birth - 出生日期
 * @param date - 填表时间
 * @param a01 - 孩子生父的近视度数为多少？
 * @param a02 - 孩子生母的近视度数为多少？
 * @param a05 - 近1年内，您的孩子是否会吸到二手烟（指每日被动吸入烟草烟雾15 min以上）？
 * @param a08 - 孩子是否有亲生的兄弟姐妹？（若没有跳过第a09和a09a题）
 * @param a09 - 孩子的亲兄弟姐妹中是否有人被诊断为近视？(若有则a09a必填)
 * @param a09a - 孩子的亲兄弟姐妹中最高度数为？
 * @param b01 - 平均每天看电视、电脑、手机、IPad平板、投影等电子屏幕的总时间(周一至周五)
 * @param b02 - 平均每天看电视、电脑、手机、IPad平板、投影等电子屏幕的总时间(周六、周日)
 * @param b013 - 您的孩子玩手机或者电子游戏机时，距离大概多远？
 * @param b014 - 当您的孩子看电视时，距离大概多远？
 * @param b015 - 您家孩子在家看电视时眼睛能否与电视的位置保持平视？
 * @param c01 - 平均每天做家庭作业和阅读纸质书籍的总时间(周一至周五)
 * @param c02 - 平均每天做家庭作业和阅读纸质书籍的总时间(周六、周日)
 * @param c05 - 平均每天放学后的文化类辅导课程总时间(周一至周五)
 * @param c06 - 平均每天放学后的文化类辅导课程总时间(周六、周日)
 * @param c07 - 请估计一下您的孩子的读写距离？
 * @param d01 - 最近一周学习日（周一到周五），平均每天在户外接触到阳光的时间
 * @param d02 - 最近一周周末，平均每天接触到阳光的时间
 * @param e01 - 最近一段时间内，您的孩子平均晚上上床睡觉时间？(周一至周五)
 * @param e02 - 最近一段时间内，您的孩子平均晚上上床睡觉时间？(周六、周日)
 * @param e03 - 最近一段时间内，您的孩子平均早晨起床时间(周一至周五)
 * @param e04 - 最近一段时间内，您的孩子平均早晨起床时间(周六、周日)
 * @param e05 - 最近一段时间内，您的孩子平均午睡多长时间(周一至周五)
 * @param e06 - 最近一段时间内，您的孩子平均午睡多长时间(周六、周日)
 * @param e08 - 您的孩子睡觉时是否开夜灯？
 * @param e09 - 您家孩子睡觉时窗外是否有光照进房间？
 * @param f01 - 根据孩子身高变化，会定期调整家里书桌椅高度
 * @param f02 - 限制孩子使用电子产品的时间
 * @param f03 - 鼓励孩子课间走出教室，到户外活动
 * @param f04 - 节假日陪伴孩子进行户外活动
 * @param f06 - 督促孩子不在躺卧/走路/吃饭/光线昏暗/阳光直射等任何一种情况或者多种情况下看书或使用电子产品
 * @param f07 - 教育孩子认真完成眼保健操
 * @param f08 - 鼓励或培养孩子掌握1～2项体育技能
 * @param f09 - 督促孩子认真完成寒暑假体育作业
 * @param f010 - 为孩子提供营养均衡的膳食
 * @param f011 - 督促孩子保持正确读写姿势，做到“一拳一尺一寸”
 * @param f012 - 检查孩子写字时，笔杆在拇指、食指和中指之间，与作业本成45度角左右
 * @param f013 - 督促孩子看电视时，眼睛距离电视大于3米
 * @param f014 - 要求孩子用电脑时，眼睛距离电脑显示屏大于50厘米
 * @param f015 - 提醒孩子夜晚读写时，打开房间顶灯和台灯
 * @param f016 - 提醒孩子夜晚看电视/使用电脑时，打开房间顶灯
 * @param f017 - 督促孩子持续用眼40分钟后，通过远眺/轻微活动/户外活动/做眼保健操/闭目等任何一种方式或多种方式放松眼睛
 * @param f018 - 主动学习科学用眼知识
 * @param f020 - 保持孩子上学日和周末作息制度基本一致
 * @param f021 - 孩子书桌主采光源位于左手侧
 * @param f022 - 在孩子卧室摆放电视等视屏产品
 * @param f023 - 如果发现孩子视力异常，会及时带孩子去医院就诊
 * @param f024 - 如果学校报告孩子近视，会及时带孩子到专业机构验光配镜
 * @param f025 - 如果孩子需要配镜，会采购和使用获得认证的眼视光相关产品
 * @param g01 - 您的孩子是否每年在学校或正规医疗机构检测视力2次以上？
 * @param g02 - 您的孩子目前是否已被眼科医师诊断为近视？
 * @param g02a - 诊断近视时孩子几周岁？
 * @param g03 - 您通过什么途径发现孩子视力下降？
 * @param g04 - 您的孩子是否采用以下一种或多种方式矫正或预防近视?（可多选）
 * @param g04a - 戴镜年数
 * @param g05 - 普通框架眼镜更换频率
 * @param g06 - 近视防控产品的获得途径
 * @param i01 - 您的孩子读写时是否使用台灯
 * @param i02 - 孩子读写时使用的台灯类型
 * @param i03 - 孩子经常活动的房间主照明灯类型
 * @param i04 - 孩子经常活动的房间灯光照度
 * @param h01 - 孩子日常行为经常：揉眼睛
 * @param h02 - 孩子日常行为经常：眨眼睛
 * @param h03 - 孩子日常行为经常：揉鼻子
 * @param h04 - 孩子日常行为经常：挤眉弄眼
 * @param h05 - 孩子日常行为经常：多动
 * @param h06 - 孩子平时有：打喷嚏
 * @param h06a - 孩子平时有：打喷嚏 几岁
 * @param h07 - 孩子平时有：流清水涕
 * @param h07a - 孩子平时有：流清水涕 几岁
 * @param h08 - 孩子平时有：鼻痒
 * @param h08a - 孩子平时有：鼻痒 几岁
 * @param h09 - 孩子平时有：鼻塞
 * @param h09a - 孩子平时有：鼻塞 几岁
 * @param h010 - 孩子平时有：眼睛痒
 * @param h010a - 孩子平时有：眼睛痒 几岁
 * @param h011 - 孩子平时有：流泪
 * @param h011a - 孩子平时有：流泪 几岁
 * @param h012 - 孩子平时有：皮肤痒
 * @param h012a - 孩子平时有：皮肤痒 几岁
 * @param h013 - 孩子平时有：咳嗽
 * @param h013a - 孩子平时有：咳嗽 几岁
 * @param h016 - 孩子（有/疑似有/无）过敏：
 * @param h016a - 孩子的过敏源类型：（过敏性结膜炎/过敏性鼻炎/哮喘/荨麻疹/食物过敏）
 * @param h016ab - 孩子的过敏源是：
 * @param h016ac - 孩子过敏的食物是：
 * @param h017 - 孩子家中养宠物
 * @param h017a - 宠物是?
 * @param h017ab - 其它宠物
 * @param h018 - 孩子母乳喂养（有/无）
 * @param h018a - 母乳喂养到孩子第几个月停止
 * @param h019 - 孩子出生（顺产/剖腹产）
 * @param h020 - 孩子每天喝牛奶（是/否）
 * @param h020a - 每天喝牛奶（ml）
 * @param h021 - 每天吃鸡蛋（是/否）
 * @param h022 - 孩子挑食吗（是/否）
 * @param h022a - 挑食喜欢吃（肉/蛋/奶/蔬菜）
 * @param h023 - 孩子睡眠
 * @param h023a - 影响孩子睡眠的是
 * @param h024 - 孩子2岁前出现皮肤湿疹
 * @param h025a - 孩子亲生父亲是否有过敏性鼻炎
 * @param h025b - 孩子亲生父亲是否有过敏性结膜
 * @param h025c - 孩子亲生父亲是否有哮喘
 * @param h025d - 孩子亲生父亲是否有荨麻疹
 * @param h025e - 孩子亲生父亲是否有其他过敏性疾病
 * @param h025ea - 亲生父亲的具体疾病
 * @param h025f - 孩子亲生母亲是否有过敏性鼻炎
 * @param h025g - 孩子亲生母亲是否有过敏性结膜
 * @param h025h - 孩子亲生母亲是否有哮喘
 * @param h025i - 孩子亲生母亲是否有荨麻疹
 * @param h025j - 孩子亲生母亲是否有其他过敏性疾病
 * @param h025ja - 亲生母亲的具体疾病
 */
export const bizQuestionnaireSaveCamsq = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/business/questionnaire/save-camsq'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['siteCode'] !== undefined) {
    queryParameters['siteCode'] = parameters['siteCode']
  }
  if (parameters['siteCode'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: siteCode'))
  }
  if (parameters['name'] !== undefined) {
    form['name'] = parameters['name']
  }
  if (parameters['name'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: name'))
  }
  if (parameters['idCard'] !== undefined) {
    form['idCard'] = parameters['idCard']
  }
  if (parameters['idCard'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: idCard'))
  }
  if (parameters['age'] !== undefined) {
    form['age'] = parameters['age']
  }
  if (parameters['age'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: age'))
  }
  if (parameters['sex'] !== undefined) {
    form['sex'] = parameters['sex']
  }
  if (parameters['sex'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: sex'))
  }
  if (parameters['schoolName'] !== undefined) {
    form['schoolName'] = parameters['schoolName']
  }
  if (parameters['schoolName'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: schoolName'))
  }
  if (parameters['code'] !== undefined) {
    form['code'] = parameters['code']
  }
  if (parameters['gradeValue'] !== undefined) {
    form['gradeValue'] = parameters['gradeValue']
  }
  if (parameters['gradeValue'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: gradeValue'))
  }
  if (parameters['className'] !== undefined) {
    form['className'] = parameters['className']
  }
  if (parameters['className'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: className'))
  }
  if (parameters['birth'] !== undefined) {
    form['birth'] = parameters['birth']
  }
  if (parameters['date'] !== undefined) {
    form['date'] = parameters['date']
  }
  if (parameters['date'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: date'))
  }
  if (parameters['a01'] !== undefined) {
    form['a01'] = parameters['a01']
  }
  if (parameters['a01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a01'))
  }
  if (parameters['a02'] !== undefined) {
    form['a02'] = parameters['a02']
  }
  if (parameters['a02'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a02'))
  }
  if (parameters['a05'] !== undefined) {
    form['a05'] = parameters['a05']
  }
  if (parameters['a05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a05'))
  }
  if (parameters['a08'] !== undefined) {
    form['a08'] = parameters['a08']
  }
  if (parameters['a08'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a08'))
  }
  if (parameters['a09'] !== undefined) {
    form['a09'] = parameters['a09']
  }
  if (parameters['a09a'] !== undefined) {
    form['a09a'] = parameters['a09a']
  }
  if (parameters['b01'] !== undefined) {
    form['b01'] = parameters['b01']
  }
  if (parameters['b01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b01'))
  }
  if (parameters['b02'] !== undefined) {
    form['b02'] = parameters['b02']
  }
  if (parameters['b02'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b02'))
  }
  if (parameters['b013'] !== undefined) {
    form['b013'] = parameters['b013']
  }
  if (parameters['b013'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b013'))
  }
  if (parameters['b014'] !== undefined) {
    form['b014'] = parameters['b014']
  }
  if (parameters['b014'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b014'))
  }
  if (parameters['b015'] !== undefined) {
    form['b015'] = parameters['b015']
  }
  if (parameters['b015'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b015'))
  }
  if (parameters['c01'] !== undefined) {
    form['c01'] = parameters['c01']
  }
  if (parameters['c01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c01'))
  }
  if (parameters['c02'] !== undefined) {
    form['c02'] = parameters['c02']
  }
  if (parameters['c02'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c02'))
  }
  if (parameters['c05'] !== undefined) {
    form['c05'] = parameters['c05']
  }
  if (parameters['c05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c05'))
  }
  if (parameters['c06'] !== undefined) {
    form['c06'] = parameters['c06']
  }
  if (parameters['c06'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c06'))
  }
  if (parameters['c07'] !== undefined) {
    form['c07'] = parameters['c07']
  }
  if (parameters['c07'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c07'))
  }
  if (parameters['d01'] !== undefined) {
    form['d01'] = parameters['d01']
  }
  if (parameters['d01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d01'))
  }
  if (parameters['d02'] !== undefined) {
    form['d02'] = parameters['d02']
  }
  if (parameters['d02'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d02'))
  }
  if (parameters['e01'] !== undefined) {
    form['e01'] = parameters['e01']
  }
  if (parameters['e01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: e01'))
  }
  if (parameters['e02'] !== undefined) {
    form['e02'] = parameters['e02']
  }
  if (parameters['e02'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: e02'))
  }
  if (parameters['e03'] !== undefined) {
    form['e03'] = parameters['e03']
  }
  if (parameters['e03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: e03'))
  }
  if (parameters['e04'] !== undefined) {
    form['e04'] = parameters['e04']
  }
  if (parameters['e04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: e04'))
  }
  if (parameters['e05'] !== undefined) {
    form['e05'] = parameters['e05']
  }
  if (parameters['e05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: e05'))
  }
  if (parameters['e06'] !== undefined) {
    form['e06'] = parameters['e06']
  }
  if (parameters['e06'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: e06'))
  }
  if (parameters['e08'] !== undefined) {
    form['e08'] = parameters['e08']
  }
  if (parameters['e08'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: e08'))
  }
  if (parameters['e09'] !== undefined) {
    form['e09'] = parameters['e09']
  }
  if (parameters['e09'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: e09'))
  }
  if (parameters['f01'] !== undefined) {
    form['f01'] = parameters['f01']
  }
  if (parameters['f01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f01'))
  }
  if (parameters['f02'] !== undefined) {
    form['f02'] = parameters['f02']
  }
  if (parameters['f02'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f02'))
  }
  if (parameters['f03'] !== undefined) {
    form['f03'] = parameters['f03']
  }
  if (parameters['f03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f03'))
  }
  if (parameters['f04'] !== undefined) {
    form['f04'] = parameters['f04']
  }
  if (parameters['f04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f04'))
  }
  if (parameters['f06'] !== undefined) {
    form['f06'] = parameters['f06']
  }
  if (parameters['f06'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f06'))
  }
  if (parameters['f07'] !== undefined) {
    form['f07'] = parameters['f07']
  }
  if (parameters['f07'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f07'))
  }
  if (parameters['f08'] !== undefined) {
    form['f08'] = parameters['f08']
  }
  if (parameters['f08'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f08'))
  }
  if (parameters['f09'] !== undefined) {
    form['f09'] = parameters['f09']
  }
  if (parameters['f09'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f09'))
  }
  if (parameters['f010'] !== undefined) {
    form['f010'] = parameters['f010']
  }
  if (parameters['f010'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f010'))
  }
  if (parameters['f011'] !== undefined) {
    form['f011'] = parameters['f011']
  }
  if (parameters['f011'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f011'))
  }
  if (parameters['f012'] !== undefined) {
    form['f012'] = parameters['f012']
  }
  if (parameters['f012'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f012'))
  }
  if (parameters['f013'] !== undefined) {
    form['f013'] = parameters['f013']
  }
  if (parameters['f013'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f013'))
  }
  if (parameters['f014'] !== undefined) {
    form['f014'] = parameters['f014']
  }
  if (parameters['f014'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f014'))
  }
  if (parameters['f015'] !== undefined) {
    form['f015'] = parameters['f015']
  }
  if (parameters['f015'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f015'))
  }
  if (parameters['f016'] !== undefined) {
    form['f016'] = parameters['f016']
  }
  if (parameters['f016'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f016'))
  }
  if (parameters['f017'] !== undefined) {
    form['f017'] = parameters['f017']
  }
  if (parameters['f017'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f017'))
  }
  if (parameters['f018'] !== undefined) {
    form['f018'] = parameters['f018']
  }
  if (parameters['f018'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f018'))
  }
  if (parameters['f020'] !== undefined) {
    form['f020'] = parameters['f020']
  }
  if (parameters['f020'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f020'))
  }
  if (parameters['f021'] !== undefined) {
    form['f021'] = parameters['f021']
  }
  if (parameters['f021'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f021'))
  }
  if (parameters['f022'] !== undefined) {
    form['f022'] = parameters['f022']
  }
  if (parameters['f022'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f022'))
  }
  if (parameters['f023'] !== undefined) {
    form['f023'] = parameters['f023']
  }
  if (parameters['f023'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f023'))
  }
  if (parameters['f024'] !== undefined) {
    form['f024'] = parameters['f024']
  }
  if (parameters['f024'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f024'))
  }
  if (parameters['f025'] !== undefined) {
    form['f025'] = parameters['f025']
  }
  if (parameters['f025'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f025'))
  }
  if (parameters['g01'] !== undefined) {
    form['g01'] = parameters['g01']
  }
  if (parameters['g01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: g01'))
  }
  if (parameters['g02'] !== undefined) {
    form['g02'] = parameters['g02']
  }
  if (parameters['g02'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: g02'))
  }
  if (parameters['g02a'] !== undefined) {
    form['g02a'] = parameters['g02a']
  }
  if (parameters['g03'] !== undefined) {
    form['g03'] = parameters['g03']
  }
  if (parameters['g03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: g03'))
  }
  if (parameters['g04'] !== undefined) {
    form['g04'] = parameters['g04']
  }
  if (parameters['g04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: g04'))
  }
  if (parameters['g04a'] !== undefined) {
    form['g04a'] = parameters['g04a']
  }
  if (parameters['g05'] !== undefined) {
    form['g05'] = parameters['g05']
  }
  if (parameters['g06'] !== undefined) {
    form['g06'] = parameters['g06']
  }
  if (parameters['i01'] !== undefined) {
    form['i01'] = parameters['i01']
  }
  if (parameters['i01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: i01'))
  }
  if (parameters['i02'] !== undefined) {
    form['i02'] = parameters['i02']
  }
  if (parameters['i03'] !== undefined) {
    form['i03'] = parameters['i03']
  }
  if (parameters['i03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: i03'))
  }
  if (parameters['i04'] !== undefined) {
    form['i04'] = parameters['i04']
  }
  if (parameters['i04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: i04'))
  }
  if (parameters['h01'] !== undefined) {
    form['h01'] = parameters['h01']
  }
  if (parameters['h01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h01'))
  }
  if (parameters['h02'] !== undefined) {
    form['h02'] = parameters['h02']
  }
  if (parameters['h02'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h02'))
  }
  if (parameters['h03'] !== undefined) {
    form['h03'] = parameters['h03']
  }
  if (parameters['h03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h03'))
  }
  if (parameters['h04'] !== undefined) {
    form['h04'] = parameters['h04']
  }
  if (parameters['h04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h04'))
  }
  if (parameters['h05'] !== undefined) {
    form['h05'] = parameters['h05']
  }
  if (parameters['h05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h05'))
  }
  if (parameters['h06'] !== undefined) {
    form['h06'] = parameters['h06']
  }
  if (parameters['h06'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h06'))
  }
  if (parameters['h06a'] !== undefined) {
    form['h06a'] = parameters['h06a']
  }
  if (parameters['h07'] !== undefined) {
    form['h07'] = parameters['h07']
  }
  if (parameters['h07'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h07'))
  }
  if (parameters['h07a'] !== undefined) {
    form['h07a'] = parameters['h07a']
  }
  if (parameters['h08'] !== undefined) {
    form['h08'] = parameters['h08']
  }
  if (parameters['h08'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h08'))
  }
  if (parameters['h08a'] !== undefined) {
    form['h08a'] = parameters['h08a']
  }
  if (parameters['h09'] !== undefined) {
    form['h09'] = parameters['h09']
  }
  if (parameters['h09'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h09'))
  }
  if (parameters['h09a'] !== undefined) {
    form['h09a'] = parameters['h09a']
  }
  if (parameters['h010'] !== undefined) {
    form['h010'] = parameters['h010']
  }
  if (parameters['h010'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h010'))
  }
  if (parameters['h010a'] !== undefined) {
    form['h010a'] = parameters['h010a']
  }
  if (parameters['h011'] !== undefined) {
    form['h011'] = parameters['h011']
  }
  if (parameters['h011'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h011'))
  }
  if (parameters['h011a'] !== undefined) {
    form['h011a'] = parameters['h011a']
  }
  if (parameters['h012'] !== undefined) {
    form['h012'] = parameters['h012']
  }
  if (parameters['h012'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h012'))
  }
  if (parameters['h012a'] !== undefined) {
    form['h012a'] = parameters['h012a']
  }
  if (parameters['h013'] !== undefined) {
    form['h013'] = parameters['h013']
  }
  if (parameters['h013'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h013'))
  }
  if (parameters['h013a'] !== undefined) {
    form['h013a'] = parameters['h013a']
  }
  if (parameters['h016'] !== undefined) {
    form['h016'] = parameters['h016']
  }
  if (parameters['h016'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h016'))
  }
  if (parameters['h016a'] !== undefined) {
    form['h016a'] = parameters['h016a']
  }
  if (parameters['h016ab'] !== undefined) {
    form['h016ab'] = parameters['h016ab']
  }
  if (parameters['h016ac'] !== undefined) {
    form['h016ac'] = parameters['h016ac']
  }
  if (parameters['h017'] !== undefined) {
    form['h017'] = parameters['h017']
  }
  if (parameters['h017'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h017'))
  }
  if (parameters['h017a'] !== undefined) {
    form['h017a'] = parameters['h017a']
  }
  if (parameters['h017ab'] !== undefined) {
    form['h017ab'] = parameters['h017ab']
  }
  if (parameters['h018'] !== undefined) {
    form['h018'] = parameters['h018']
  }
  if (parameters['h018'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h018'))
  }
  if (parameters['h018a'] !== undefined) {
    form['h018a'] = parameters['h018a']
  }
  if (parameters['h019'] !== undefined) {
    form['h019'] = parameters['h019']
  }
  if (parameters['h019'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h019'))
  }
  if (parameters['h020'] !== undefined) {
    form['h020'] = parameters['h020']
  }
  if (parameters['h020'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h020'))
  }
  if (parameters['h020a'] !== undefined) {
    form['h020a'] = parameters['h020a']
  }
  if (parameters['h021'] !== undefined) {
    form['h021'] = parameters['h021']
  }
  if (parameters['h021'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h021'))
  }
  if (parameters['h022'] !== undefined) {
    form['h022'] = parameters['h022']
  }
  if (parameters['h022'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h022'))
  }
  if (parameters['h022a'] !== undefined) {
    form['h022a'] = parameters['h022a']
  }
  if (parameters['h023'] !== undefined) {
    form['h023'] = parameters['h023']
  }
  if (parameters['h023'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h023'))
  }
  if (parameters['h023a'] !== undefined) {
    form['h023a'] = parameters['h023a']
  }
  if (parameters['h023a'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h023A'))
  }
  if (parameters['h024'] !== undefined) {
    form['h024'] = parameters['h024']
  }
  if (parameters['h024'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h024'))
  }
  if (parameters['h025a'] !== undefined) {
    form['h025a'] = parameters['h025a']
  }
  if (parameters['h025a'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h025A'))
  }
  if (parameters['h025b'] !== undefined) {
    form['h025b'] = parameters['h025b']
  }
  if (parameters['h025b'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h025B'))
  }
  if (parameters['h025c'] !== undefined) {
    form['h025c'] = parameters['h025c']
  }
  if (parameters['h025c'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h025C'))
  }
  if (parameters['h025d'] !== undefined) {
    form['h025d'] = parameters['h025d']
  }
  if (parameters['h025d'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h025D'))
  }
  if (parameters['h025e'] !== undefined) {
    form['h025e'] = parameters['h025e']
  }
  if (parameters['h025e'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h025E'))
  }
  if (parameters['h025ea'] !== undefined) {
    form['h025ea'] = parameters['h025ea']
  }
  if (parameters['h025f'] !== undefined) {
    form['h025f'] = parameters['h025f']
  }
  if (parameters['h025f'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h025F'))
  }
  if (parameters['h025g'] !== undefined) {
    form['h025g'] = parameters['h025g']
  }
  if (parameters['h025g'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h025G'))
  }
  if (parameters['h025h'] !== undefined) {
    form['h025h'] = parameters['h025h']
  }
  if (parameters['h025h'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h025H'))
  }
  if (parameters['h025i'] !== undefined) {
    form['h025i'] = parameters['h025i']
  }
  if (parameters['h025i'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h025I'))
  }
  if (parameters['h025j'] !== undefined) {
    form['h025j'] = parameters['h025j']
  }
  if (parameters['h025j'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h025J'))
  }
  if (parameters['h025ja'] !== undefined) {
    form['h025ja'] = parameters['h025ja']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const bizQuestionnaireSaveCamsqFormData = function(formData) {
  const url = bizQuestionnaireSaveCamsq_RAW_URL()
  const method = bizQuestionnaireSaveCamsq_TYPE()
  return axios[method](url, formData)
}
export const bizQuestionnaireSaveCamsq_RAW_URL = function() {
  return '/business/questionnaire/save-camsq'
}
export const bizQuestionnaireSaveCamsq_TYPE = function() {
  return 'post'
}
export const bizQuestionnaireSaveCamsq_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/business/questionnaire/save-camsq'
  if (parameters['siteCode'] !== undefined) {
    queryParameters['siteCode'] = parameters['siteCode']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 保存中学学生健康状况及影响因素调查表
 * request: bizQuestionnaireSaveMshiq
 * formData: bizQuestionnaireSaveMshiqFormData
 * url: bizQuestionnaireSaveMshiq_URL
 * method: bizQuestionnaireSaveMshiq_TYPE
 * raw_url: bizQuestionnaireSaveMshiq_RAW_URL
 * @param screeningPlanStudentIdNew - 
 * @param date - 
 * @param a03 - 是否住校
 * @param a04 - 你的民族
 * @param a04OtherDescription - 你的民族其他描述
 * @param a05 - 近半年，和你一起生活的家庭成员人数 近半年，和你一起生活的家庭成员人数
 * @param a051 - 当A05的值大于0时必填，反之不填。他们分别包括（可多选）
 * @param a052 - 当A05的值大于0时必填，反之不填, 家庭中同住人员最常说的话是（可多选）
 * @param a052Other - 当A052选择其他必填，反之不填,家庭中同住人员最常说的话是其他备注
 * @param a06 - X
 * @param a07 - 最近两周内，你是否因生病请假？
 * @param a07Day - 当A07的值为1时必填，反之不填。最近两周内，你因生病请假天数
 * @param a08 - 过去一年内，你是否因生病休学？
 * @param a08Month - 过去一年内，你是否因生病休学几个月
 * @param a09 - 是否来月经/遗精？
 * @param a091 - 首次月经/遗精的年龄
 * @param b01 - 你喝过几次含糖饮料？（如可乐、冰红茶、果粒橙、营养快线等）
 * @param b02 - X
 * @param b03 - 你吃过几次油炸食物？（如油条、油饼、炸薯条、炸鸡翅等）
 * @param b04 - 你吃过几次新鲜水果（不包括罐水果罐头）？
 * @param b041 - 你通常每天吃几种新鲜水果？
 * @param b05 - 你吃过几次蔬菜？（生熟均算，如沙拉、生吃或经烹饪后）
 * @param b051 - 过去7天里，你通常每天吃几种蔬菜？
 * @param b06 - 你是否每天吃早餐？
 * @param b061 - 过去7天里，你每天吃早餐种类（可多选）？
 * @param b07 - 你有几天能做到每至少 你有几天能做到每至少60分钟及以上中高强度运动（可累计）？（中高强度运动是指让你气喘吁或者心跳加快的运动。如跑步、篮球、足球、游泳、健身房内跳健身操、搬重物等）
 * @param b071 - 周末或者节假日，你能做到每天至少 60 分钟及以上中高强度运动（可累计）？
 * @param b08Minute - 当B08的值为1时必填，反之不填
 * @param b09 - 你现在平均每星期上几节体育课？
 * @param b10 - 过去7天里，你通常每天喝牛奶、酸奶、豆浆或豆奶的天数？
 * @param c01 - 过去12个月里，你是否曾经受到严重伤害（严重伤害指由于伤害而需要到医院治 疗，或不能上学或影响日常活动一天及以上）？
 * @param c01Times - 当C01的值为1时必填，反之不填.过去12个月里，你曾经受到严重伤害次数？
 * @param c021 - 被恶意取笑
 * @param c022 - 被索要财物
 * @param c023 - 被有意排斥在集体活动之外或被孤立
 * @param c024 - 被威胁、恐吓
 * @param c025 - 被打、踢、推、挤或关在屋里
 * @param c026 - 因为身体缺陷或长相而被取笑
 * @param c04 - 过去30天里，你步行过马路时是否不走人行横道？
 * @param c05 - 过去12个月里，你是否去过没有安全措施（即没有专门人员管理）的地方游泳？
 * @param c05Times - 当C05的值为2时必填，反之不填.过去12个月里，你去过几次没有安全措施（即没有专门人员管理）的地方游泳？
 * @param c06 - 过去30天，你是否曾被家长打骂？
 * @param c06Times - 当C06的值为2时必填，反之不填.过去30天，你曾被家长打骂的次数？
 * @param c07 - 是否有人通过电子传媒恶意取笑、谩骂、威胁、恐吓或者散布有关你的谣言、影像或视频等？
 * @param c08 - 请回忆一下，你持续受到欺负多长时间了？
 * @param d01 - 你是否吸过烟，即使只吸过一两口？
 * @param d01AgeOfFirst - 当D01的值为1时必填，反之不填.第一次尝试吸烟的年龄？
 * @param d02 - 当D01的值为1时必填，反之不填.过去30天里，你吸过烟吗？
 * @param d02Day - 当D02的值为2时必填，反之不填.过去30天里吸过烟的天数？
 * @param d03 - 当D01的值为1时必填，反之不填.过去30天里,你通常每天吸多少支烟?
 * @param d04 - 过去7天里，是否有人当着你的面吸烟？
 * @param d05 - 你是否喝过一整杯酒？（相当于一听啤酒，一小盅白酒，一杯葡萄酒或黄酒）
 * @param d05AgeOfFirst - 当D05的值为1时必填，反之不填.第一次喝酒的年龄？
 * @param d06 - 到目前为止，你是否使用过下列物质（没有医生许可）（可多选）
 * @param d06Description - 到目前为止，你是否使用过下列物质其它说明
 * @param d07 - 到目前为止，你是否为了高兴而吸入胶水、汽油等可吸入溶剂？
 * @param d08 - 到目前为止，除治疗咳嗽外，你是否使用过止咳药水？
 * @param d081 - 每次服用止咳药水的量是多少？
 * @param d09 - 到目前为止，在没有医生许可情况下，你是否使用镇静催眠类药品？
 * @param e01 - 从来没有上过网
 * @param e011 - 当E01的值为2时必填，反之不填.过去7天，每天上网时间小时数？
 * @param e021 - 当E01的值为2时必填，反之不填.经常上网，即使不上网，脑中也一直浮现与网络有关的事情
 * @param e022 - 当E01的值为2时必填，反之不填.一旦不能上网，就感到不舒服或不愿意干别的事，而上网则缓解
 * @param e023 - 当E01的值为2时必填，反之不填.为得到满足感增加上网时间
 * @param e024 - 当E01的值为2时必填，反之不填.因为上网而对其他娱乐活动（爱好、会见朋友）失去了兴趣
 * @param e025 - 当E01的值为2时必填，反之不填.多次想停止上网，但总不能控制自己
 * @param e026 - 当E01的值为2时必填，反之不填.因为上网而不能完成作业或逃学
 * @param e027 - 当E01的值为2时必填，反之不填.向家长或老师、同学隐瞒自己上网的事实
 * @param e028 - 当E01的值为2时必填，反之不填.明知负面后果（睡眠不足、上课迟到、与父母争执）而继续上网
 * @param e029 - 当E01的值为2时必填，反之不填.为了逃避现实、摆脱自己的困境或郁闷、无助、焦虑情绪才上网
 * @param f01 - 你是否使用耳机（至少连续30分钟）
 * @param f01AgeOfFirst - 当F01的值为2时必填，反之不填.你第一次使用耳机的岁数
 * @param f02 - 当F01的值为2时必填，反之不填.过去7天，你平均每天使用耳机分钟数 --> 时间选项
 * @param f03 - 当F01的值为2时必填，反之不填.过去7天，你连续使用耳机超过60分钟的次数
 * @param f04 - 当F01的值为2时必填，反之不填.过去7天，你是否在嘈杂的环境中（如车站、地铁、商场等需要你调高声量地方）长时间使用耳机(至少连续30分钟)
 * @param f05 - 当F01的值为2时必填，反之不填.最近1个月，你是否感觉到自己听声音不如过去清楚了
 * @param g01 - 你在学校接受过有关青春期知识的教育吗？
 * @param g02 - 你在学校接受过艾滋病预防教育吗
 * @param h01 - 平常并不困扰我的事现在使我心烦
 * @param h02 - 我不想吃东西；我胃口不好
 * @param h03 - 我觉得即便有家人或朋友帮助也无法摆脱这种苦闷
 * @param h04 - 我同别人一样感觉良好
 * @param h05 - 我很难集中精力做事
 * @param h06 - 我感到压抑
 * @param h07 - 我感到做什么事都很费力
 * @param h08 - 我觉得未来有希望
 * @param h09 - 我认为我的生活一无是处
 * @param h10 - 我感到恐惧
 * @param h11 - 我睡觉后不能缓解疲劳
 * @param h12 - 我很幸福
 * @param h13 - 我比平时话少了
 * @param h14 - 我感到孤独
 * @param h15 - 人们对我不友好
 * @param h16 - 我生活快乐
 * @param h17 - 我曾经放声痛哭
 * @param h18 - 我感到忧愁
 * @param h19 - 我觉得别人厌恶我
 * @param h20 - 我觉得无法继续我日常的生活学习
 * @param h21 - 感觉紧张，焦虑或急切
 * @param h22 - 不能够停止或控制担忧
 * @param h23 - 对各种恶样的事情担忧过多
 * @param h24 - 很难放松下来
 * @param h25 - 由于不安而无法静坐
 * @param h26 - 变得容易烦恼或急躁
 * @param h27 - 感到似乎将有可怕的事情发生而害怕
 * @param h28 - 担你被心理问题困扰时，你是否寻求过外界的帮助？
 * @param h281 - 你寻求了什么形式的帮助
 * @param h282 - 寻求帮助后你的心理问题是否得到有效缓解？
 * @param i01 - 你每天的刷牙习惯是
 * @param i011 - 您刷牙时是否使用含氟牙膏
 * @param i02 - 最近2周内，你的身体是否出现过以下症状？（可多选）
 * @param i021 - 当你出现以上症状时，你是否还坚持上课？
 * @param i05 - 在公共场所，当你想吐痰时，你一般怎么做？
 * @param i06 - 在公共场所，当你咳嗽、打喷嚏时，你一般怎么做?
 * @param i07 - 
 * @param i08 - 
 * @param i09 - 
 */
export const bizQuestionnaireSaveMshiq = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/business/questionnaire/save-mshiq'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['screeningPlanStudentIdNew'] !== undefined) {
    form['screeningPlanStudentIdNew'] = parameters['screeningPlanStudentIdNew']
  }
  if (parameters['screeningPlanStudentIdNew'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningPlanStudentIdNew'))
  }
  if (parameters['date'] !== undefined) {
    form['date'] = parameters['date']
  }
  if (parameters['date'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: date'))
  }
  if (parameters['a03'] !== undefined) {
    form['a03'] = parameters['a03']
  }
  if (parameters['a03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a03'))
  }
  if (parameters['a04'] !== undefined) {
    form['a04'] = parameters['a04']
  }
  if (parameters['a04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a04'))
  }
  if (parameters['a04OtherDescription'] !== undefined) {
    form['a04OtherDescription'] = parameters['a04OtherDescription']
  }
  if (parameters['a05'] !== undefined) {
    form['a05'] = parameters['a05']
  }
  if (parameters['a05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a05'))
  }
  if (parameters['a051'] !== undefined) {
    form['a051'] = parameters['a051']
  }
  if (parameters['a052'] !== undefined) {
    form['a052'] = parameters['a052']
  }
  if (parameters['a052Other'] !== undefined) {
    form['a052Other'] = parameters['a052Other']
  }
  if (parameters['a06'] !== undefined) {
    form['a06'] = parameters['a06']
  }
  if (parameters['a07'] !== undefined) {
    form['a07'] = parameters['a07']
  }
  if (parameters['a07'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a07'))
  }
  if (parameters['a07Day'] !== undefined) {
    form['a07Day'] = parameters['a07Day']
  }
  if (parameters['a08'] !== undefined) {
    form['a08'] = parameters['a08']
  }
  if (parameters['a08'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a08'))
  }
  if (parameters['a08Month'] !== undefined) {
    form['a08Month'] = parameters['a08Month']
  }
  if (parameters['a09'] !== undefined) {
    form['a09'] = parameters['a09']
  }
  if (parameters['a09'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a09'))
  }
  if (parameters['a091'] !== undefined) {
    form['a091'] = parameters['a091']
  }
  if (parameters['b01'] !== undefined) {
    form['b01'] = parameters['b01']
  }
  if (parameters['b01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b01'))
  }
  if (parameters['b02'] !== undefined) {
    form['b02'] = parameters['b02']
  }
  if (parameters['b03'] !== undefined) {
    form['b03'] = parameters['b03']
  }
  if (parameters['b03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b03'))
  }
  if (parameters['b04'] !== undefined) {
    form['b04'] = parameters['b04']
  }
  if (parameters['b04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b04'))
  }
  if (parameters['b041'] !== undefined) {
    form['b041'] = parameters['b041']
  }
  if (parameters['b05'] !== undefined) {
    form['b05'] = parameters['b05']
  }
  if (parameters['b05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b05'))
  }
  if (parameters['b051'] !== undefined) {
    form['b051'] = parameters['b051']
  }
  if (parameters['b06'] !== undefined) {
    form['b06'] = parameters['b06']
  }
  if (parameters['b06'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b06'))
  }
  if (parameters['b061'] !== undefined) {
    form['b061'] = parameters['b061']
  }
  if (parameters['b07'] !== undefined) {
    form['b07'] = parameters['b07']
  }
  if (parameters['b07'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b07'))
  }
  if (parameters['b071'] !== undefined) {
    form['b071'] = parameters['b071']
  }
  if (parameters['b08Minute'] !== undefined) {
    form['b08Minute'] = parameters['b08Minute']
  }
  if (parameters['b09'] !== undefined) {
    form['b09'] = parameters['b09']
  }
  if (parameters['b09'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b09'))
  }
  if (parameters['b10'] !== undefined) {
    form['b10'] = parameters['b10']
  }
  if (parameters['b10'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b10'))
  }
  if (parameters['c01'] !== undefined) {
    form['c01'] = parameters['c01']
  }
  if (parameters['c01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c01'))
  }
  if (parameters['c01Times'] !== undefined) {
    form['c01Times'] = parameters['c01Times']
  }
  if (parameters['c021'] !== undefined) {
    form['c021'] = parameters['c021']
  }
  if (parameters['c021'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c021'))
  }
  if (parameters['c022'] !== undefined) {
    form['c022'] = parameters['c022']
  }
  if (parameters['c022'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c022'))
  }
  if (parameters['c023'] !== undefined) {
    form['c023'] = parameters['c023']
  }
  if (parameters['c023'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c023'))
  }
  if (parameters['c024'] !== undefined) {
    form['c024'] = parameters['c024']
  }
  if (parameters['c024'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c024'))
  }
  if (parameters['c025'] !== undefined) {
    form['c025'] = parameters['c025']
  }
  if (parameters['c025'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c025'))
  }
  if (parameters['c026'] !== undefined) {
    form['c026'] = parameters['c026']
  }
  if (parameters['c026'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c026'))
  }
  if (parameters['c04'] !== undefined) {
    form['c04'] = parameters['c04']
  }
  if (parameters['c04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c04'))
  }
  if (parameters['c05'] !== undefined) {
    form['c05'] = parameters['c05']
  }
  if (parameters['c05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c05'))
  }
  if (parameters['c05Times'] !== undefined) {
    form['c05Times'] = parameters['c05Times']
  }
  if (parameters['c06'] !== undefined) {
    form['c06'] = parameters['c06']
  }
  if (parameters['c06'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c06'))
  }
  if (parameters['c06Times'] !== undefined) {
    form['c06Times'] = parameters['c06Times']
  }
  if (parameters['c07'] !== undefined) {
    form['c07'] = parameters['c07']
  }
  if (parameters['c08'] !== undefined) {
    form['c08'] = parameters['c08']
  }
  if (parameters['d01'] !== undefined) {
    form['d01'] = parameters['d01']
  }
  if (parameters['d01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d01'))
  }
  if (parameters['d01AgeOfFirst'] !== undefined) {
    form['d01AgeOfFirst'] = parameters['d01AgeOfFirst']
  }
  if (parameters['d02'] !== undefined) {
    form['d02'] = parameters['d02']
  }
  if (parameters['d02Day'] !== undefined) {
    form['d02Day'] = parameters['d02Day']
  }
  if (parameters['d03'] !== undefined) {
    form['d03'] = parameters['d03']
  }
  if (parameters['d04'] !== undefined) {
    form['d04'] = parameters['d04']
  }
  if (parameters['d04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d04'))
  }
  if (parameters['d05'] !== undefined) {
    form['d05'] = parameters['d05']
  }
  if (parameters['d05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d05'))
  }
  if (parameters['d05AgeOfFirst'] !== undefined) {
    form['d05AgeOfFirst'] = parameters['d05AgeOfFirst']
  }
  if (parameters['d06'] !== undefined) {
    form['d06'] = parameters['d06']
  }
  if (parameters['d06'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d06'))
  }
  if (parameters['d06Description'] !== undefined) {
    form['d06Description'] = parameters['d06Description']
  }
  if (parameters['d07'] !== undefined) {
    form['d07'] = parameters['d07']
  }
  if (parameters['d07'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d07'))
  }
  if (parameters['d08'] !== undefined) {
    form['d08'] = parameters['d08']
  }
  if (parameters['d08'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d08'))
  }
  if (parameters['d081'] !== undefined) {
    form['d081'] = parameters['d081']
  }
  if (parameters['d09'] !== undefined) {
    form['d09'] = parameters['d09']
  }
  if (parameters['d09'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d09'))
  }
  if (parameters['e01'] !== undefined) {
    form['e01'] = parameters['e01']
  }
  if (parameters['e01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: e01'))
  }
  if (parameters['e011'] !== undefined) {
    form['e011'] = parameters['e011']
  }
  if (parameters['e021'] !== undefined) {
    form['e021'] = parameters['e021']
  }
  if (parameters['e022'] !== undefined) {
    form['e022'] = parameters['e022']
  }
  if (parameters['e023'] !== undefined) {
    form['e023'] = parameters['e023']
  }
  if (parameters['e024'] !== undefined) {
    form['e024'] = parameters['e024']
  }
  if (parameters['e025'] !== undefined) {
    form['e025'] = parameters['e025']
  }
  if (parameters['e026'] !== undefined) {
    form['e026'] = parameters['e026']
  }
  if (parameters['e027'] !== undefined) {
    form['e027'] = parameters['e027']
  }
  if (parameters['e028'] !== undefined) {
    form['e028'] = parameters['e028']
  }
  if (parameters['e029'] !== undefined) {
    form['e029'] = parameters['e029']
  }
  if (parameters['f01'] !== undefined) {
    form['f01'] = parameters['f01']
  }
  if (parameters['f01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f01'))
  }
  if (parameters['f01AgeOfFirst'] !== undefined) {
    form['f01AgeOfFirst'] = parameters['f01AgeOfFirst']
  }
  if (parameters['f02'] !== undefined) {
    form['f02'] = parameters['f02']
  }
  if (parameters['f03'] !== undefined) {
    form['f03'] = parameters['f03']
  }
  if (parameters['f04'] !== undefined) {
    form['f04'] = parameters['f04']
  }
  if (parameters['f05'] !== undefined) {
    form['f05'] = parameters['f05']
  }
  if (parameters['g01'] !== undefined) {
    form['g01'] = parameters['g01']
  }
  if (parameters['g01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: g01'))
  }
  if (parameters['g02'] !== undefined) {
    form['g02'] = parameters['g02']
  }
  if (parameters['g02'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: g02'))
  }
  if (parameters['h01'] !== undefined) {
    form['h01'] = parameters['h01']
  }
  if (parameters['h01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h01'))
  }
  if (parameters['h02'] !== undefined) {
    form['h02'] = parameters['h02']
  }
  if (parameters['h02'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h02'))
  }
  if (parameters['h03'] !== undefined) {
    form['h03'] = parameters['h03']
  }
  if (parameters['h03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h03'))
  }
  if (parameters['h04'] !== undefined) {
    form['h04'] = parameters['h04']
  }
  if (parameters['h04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h04'))
  }
  if (parameters['h05'] !== undefined) {
    form['h05'] = parameters['h05']
  }
  if (parameters['h05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h05'))
  }
  if (parameters['h06'] !== undefined) {
    form['h06'] = parameters['h06']
  }
  if (parameters['h06'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h06'))
  }
  if (parameters['h07'] !== undefined) {
    form['h07'] = parameters['h07']
  }
  if (parameters['h07'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h07'))
  }
  if (parameters['h08'] !== undefined) {
    form['h08'] = parameters['h08']
  }
  if (parameters['h08'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h08'))
  }
  if (parameters['h09'] !== undefined) {
    form['h09'] = parameters['h09']
  }
  if (parameters['h09'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h09'))
  }
  if (parameters['h10'] !== undefined) {
    form['h10'] = parameters['h10']
  }
  if (parameters['h10'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h10'))
  }
  if (parameters['h11'] !== undefined) {
    form['h11'] = parameters['h11']
  }
  if (parameters['h11'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h11'))
  }
  if (parameters['h12'] !== undefined) {
    form['h12'] = parameters['h12']
  }
  if (parameters['h12'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h12'))
  }
  if (parameters['h13'] !== undefined) {
    form['h13'] = parameters['h13']
  }
  if (parameters['h13'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h13'))
  }
  if (parameters['h14'] !== undefined) {
    form['h14'] = parameters['h14']
  }
  if (parameters['h14'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h14'))
  }
  if (parameters['h15'] !== undefined) {
    form['h15'] = parameters['h15']
  }
  if (parameters['h15'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h15'))
  }
  if (parameters['h16'] !== undefined) {
    form['h16'] = parameters['h16']
  }
  if (parameters['h16'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h16'))
  }
  if (parameters['h17'] !== undefined) {
    form['h17'] = parameters['h17']
  }
  if (parameters['h17'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h17'))
  }
  if (parameters['h18'] !== undefined) {
    form['h18'] = parameters['h18']
  }
  if (parameters['h18'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h18'))
  }
  if (parameters['h19'] !== undefined) {
    form['h19'] = parameters['h19']
  }
  if (parameters['h19'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h19'))
  }
  if (parameters['h20'] !== undefined) {
    form['h20'] = parameters['h20']
  }
  if (parameters['h20'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h20'))
  }
  if (parameters['h21'] !== undefined) {
    form['h21'] = parameters['h21']
  }
  if (parameters['h21'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h21'))
  }
  if (parameters['h22'] !== undefined) {
    form['h22'] = parameters['h22']
  }
  if (parameters['h22'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h22'))
  }
  if (parameters['h23'] !== undefined) {
    form['h23'] = parameters['h23']
  }
  if (parameters['h23'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h23'))
  }
  if (parameters['h24'] !== undefined) {
    form['h24'] = parameters['h24']
  }
  if (parameters['h24'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h24'))
  }
  if (parameters['h25'] !== undefined) {
    form['h25'] = parameters['h25']
  }
  if (parameters['h25'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h25'))
  }
  if (parameters['h26'] !== undefined) {
    form['h26'] = parameters['h26']
  }
  if (parameters['h26'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h26'))
  }
  if (parameters['h27'] !== undefined) {
    form['h27'] = parameters['h27']
  }
  if (parameters['h27'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h27'))
  }
  if (parameters['h28'] !== undefined) {
    form['h28'] = parameters['h28']
  }
  if (parameters['h28'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h28'))
  }
  if (parameters['h281'] !== undefined) {
    form['h281'] = parameters['h281']
  }
  if (parameters['h282'] !== undefined) {
    form['h282'] = parameters['h282']
  }
  if (parameters['i01'] !== undefined) {
    form['i01'] = parameters['i01']
  }
  if (parameters['i01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: i01'))
  }
  if (parameters['i011'] !== undefined) {
    form['i011'] = parameters['i011']
  }
  if (parameters['i02'] !== undefined) {
    form['i02'] = parameters['i02']
  }
  if (parameters['i02'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: i02'))
  }
  if (parameters['i021'] !== undefined) {
    form['i021'] = parameters['i021']
  }
  if (parameters['i05'] !== undefined) {
    form['i05'] = parameters['i05']
  }
  if (parameters['i05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: i05'))
  }
  if (parameters['i06'] !== undefined) {
    form['i06'] = parameters['i06']
  }
  if (parameters['i06'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: i06'))
  }
  if (parameters['i07'] !== undefined) {
    form['i07'] = parameters['i07']
  }
  if (parameters['i07'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: i07'))
  }
  if (parameters['i08'] !== undefined) {
    form['i08'] = parameters['i08']
  }
  if (parameters['i08'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: i08'))
  }
  if (parameters['i09'] !== undefined) {
    form['i09'] = parameters['i09']
  }
  if (parameters['i09'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: i09'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const bizQuestionnaireSaveMshiqFormData = function(formData) {
  const url = bizQuestionnaireSaveMshiq_RAW_URL()
  const method = bizQuestionnaireSaveMshiq_TYPE()
  return axios[method](url, formData)
}
export const bizQuestionnaireSaveMshiq_RAW_URL = function() {
  return '/business/questionnaire/save-mshiq'
}
export const bizQuestionnaireSaveMshiq_TYPE = function() {
  return 'post'
}
export const bizQuestionnaireSaveMshiq_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/business/questionnaire/save-mshiq'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 保存小学学生健康状况及影响因素调查表
 * request: bizQuestionnaireSavePshiq
 * formData: bizQuestionnaireSavePshiqFormData
 * url: bizQuestionnaireSavePshiq_URL
 * method: bizQuestionnaireSavePshiq_TYPE
 * raw_url: bizQuestionnaireSavePshiq_RAW_URL
 * @param screeningPlanStudentIdNew - 
 * @param date - 填表时间
 * @param a03 - 是否住校
 * @param a04 - 你的民族
 * @param a04OtherDescription - 你的民族其他描述
 * @param a05 - 近半年，和你一起生活的家庭成员人数 近半年，和你一起生活的家庭成员人数
 * @param a051 - 当A05的值大于0时必填，反之不填.他们分别包括（可多选）
 * @param a052 - 当A05的值大于0时必填，反之不填, 家庭中同住人员最常说的话是（可多选）
 * @param a052Other - 当A052选择其他必填，反之不填,家庭中同住人员最常说的话是其他备注
 * @param a06 - X你每天的刷牙习惯是？
 * @param a07 - 最近两周内，你是否因生病请假？
 * @param a07Day - 当A07的值为1时必填，反之不填。最近两周内，你因生病请假天数
 * @param a08 - 过去一年内，你是否因生病休学？
 * @param a08Month - 过去一年内，你是否因生病休学几个月
 * @param a09 - 是否来月经/遗精？
 * @param a091 - 首次月经/遗精的年龄
 * @param b01 - 你喝过几次含糖饮料？（如可乐、冰红茶、果粒橙、营养快线等）
 * @param b02 - X你吃过几次甜食？（包括糖果、蛋糕、巧克力、甜汤等）
 * @param b03 - 你吃过几次油炸食物？（如油条、油饼、炸薯条、炸鸡翅等）
 * @param b04 - 你吃过几次新鲜水果（不包括罐水果罐头）？
 * @param b041 - 过去7天里，你通常每天吃几种新鲜水果？
 * @param b05 - 你吃过几次蔬菜？（生熟均算，如沙拉、生吃或经烹饪后）
 * @param b051 - 过去7天里，你通常每天吃几种蔬菜？
 * @param b06 - 你是否每天吃早餐？
 * @param b061 - 过去7天里，你每天吃早餐种类（可多选）？
 * @param b07 - 你有几天能做到每至少 你有几天能做到每至少60分钟及以上中高强度运动（可累计）？（中高强度运动是指让你气喘吁或者心跳加快的运动。如跑步、篮球、足球、游泳、健身房内跳健身操、搬重物等）
 * @param b071 - 周末或者节假日，你能做到每天至少 60 分钟及以上中高强度运动（可累计）？
 * @param b08 - 你现在平均每星期上几节体育课？
 * @param b10 - 过去7天里，你通常每天喝牛奶、酸奶、豆浆或豆奶的天数？
 * @param c01 - 过去12个月里，你是否曾经受到严重伤害（严重伤害指由于伤害而需要到医院治 疗，或不能上学或影响日常活动一天及以上）？
 * @param c01Times - 当C01的值为1时必填，反之不填,过去12个月里，你曾经受到严重伤害次数？
 * @param c021 - 被恶意取笑
 * @param c022 - 被索要财物
 * @param c023 - 被有意排斥在集体活动之外或被孤立
 * @param c024 - 被威胁、恐吓
 * @param c025 - 被打、踢、推、挤或关在屋里
 * @param c026 - 因为身体缺陷或长相而被取笑
 * @param c03 - 过去12个月里，你与他人动手打架几次？
 * @param c04 - 过去30天里，你步行过马路时是否不走人行横道？
 * @param c05 - 过去12个月里，你是否去过没有安全措施（即没有专门人员管理）的地方游泳？
 * @param c05Times - 当C05的值为2时必填，反之不填
 * @param c06 - 过去30天，你是否曾被家长打骂？
 * @param c06Times - 当C06的值为2时必填，反之不填.过去30天，你曾被家长打骂的次数？
 * @param c07 - 是否有人通过电子传媒恶意取笑、谩骂、威胁、恐吓或者散布有关你的谣言、影像或视频等？
 * @param c08 - 请回忆一下，你持续受到欺负多长时间了？
 * @param d01 - 你是否吸过烟，即使只吸过一两口？
 * @param d01AgeOfFirst - 当D01的值为1时必填，反之不填.第一次尝试吸烟的年龄？
 * @param d03 - 过去7天里，是否有人当着你的面吸烟？
 * @param d04 - 你是否喝过一整杯酒？（相当于一听啤酒，一小盅白酒，一杯葡萄酒或黄酒）
 * @param d04AgeOfFirst - 当D04的值为1时必填，反之不填.第一次喝酒的年龄？
 * @param e01 - 你是否使用耳机（至少连续30分钟）
 * @param e01AgeOfFirst - 当E01的值为2时必填，反之不填.你第一次使用耳机的岁数
 * @param e02 - 当E01的值为2时必填，反之不填.过去7天，你平均每天使用耳机时间数选项
 * @param e03 - 当E01的值为2时必填，反之不填.过去7天，你连续使用耳机超过60分钟的次数
 * @param e04 - 当E01的值为2时必填，反之不填.过去7天，你是否在嘈杂的环境中（如车站、地铁、商场等需要你调高声量地方）长时间使用耳机(至少连续30分钟)
 * @param e05 - 当E01的值为2时必填，反之不填.最近1个月，你是否感觉到自己听声音不如过去清楚了
 * @param f01 - 你每天的刷牙习惯是
 * @param f011 - 您刷牙时是否使用含氟牙膏
 * @param f02 - 最近2周内，你的身体是否出现过以下症状？（可多选）
 * @param f021 - 当你出现以上症状时，你是否还坚持上课？
 * @param f05 - 在公共场所，当你想吐痰时，你一般怎么做？
 * @param f06 - 在公共场所，当你咳嗽、打喷嚏时，你一般怎么做?
 * @param f07 - 
 * @param f08 - 
 * @param f09 - 
 */
export const bizQuestionnaireSavePshiq = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/business/questionnaire/save-pshiq'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['screeningPlanStudentIdNew'] !== undefined) {
    form['screeningPlanStudentIdNew'] = parameters['screeningPlanStudentIdNew']
  }
  if (parameters['screeningPlanStudentIdNew'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningPlanStudentIdNew'))
  }
  if (parameters['date'] !== undefined) {
    form['date'] = parameters['date']
  }
  if (parameters['date'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: date'))
  }
  if (parameters['a03'] !== undefined) {
    form['a03'] = parameters['a03']
  }
  if (parameters['a03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a03'))
  }
  if (parameters['a04'] !== undefined) {
    form['a04'] = parameters['a04']
  }
  if (parameters['a04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a04'))
  }
  if (parameters['a04OtherDescription'] !== undefined) {
    form['a04OtherDescription'] = parameters['a04OtherDescription']
  }
  if (parameters['a05'] !== undefined) {
    form['a05'] = parameters['a05']
  }
  if (parameters['a05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a05'))
  }
  if (parameters['a051'] !== undefined) {
    form['a051'] = parameters['a051']
  }
  if (parameters['a052'] !== undefined) {
    form['a052'] = parameters['a052']
  }
  if (parameters['a052Other'] !== undefined) {
    form['a052Other'] = parameters['a052Other']
  }
  if (parameters['a06'] !== undefined) {
    form['a06'] = parameters['a06']
  }
  if (parameters['a07'] !== undefined) {
    form['a07'] = parameters['a07']
  }
  if (parameters['a07'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a07'))
  }
  if (parameters['a07Day'] !== undefined) {
    form['a07Day'] = parameters['a07Day']
  }
  if (parameters['a08'] !== undefined) {
    form['a08'] = parameters['a08']
  }
  if (parameters['a08'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a08'))
  }
  if (parameters['a08Month'] !== undefined) {
    form['a08Month'] = parameters['a08Month']
  }
  if (parameters['a09'] !== undefined) {
    form['a09'] = parameters['a09']
  }
  if (parameters['a09'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a09'))
  }
  if (parameters['a091'] !== undefined) {
    form['a091'] = parameters['a091']
  }
  if (parameters['b01'] !== undefined) {
    form['b01'] = parameters['b01']
  }
  if (parameters['b01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b01'))
  }
  if (parameters['b02'] !== undefined) {
    form['b02'] = parameters['b02']
  }
  if (parameters['b03'] !== undefined) {
    form['b03'] = parameters['b03']
  }
  if (parameters['b03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b03'))
  }
  if (parameters['b04'] !== undefined) {
    form['b04'] = parameters['b04']
  }
  if (parameters['b04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b04'))
  }
  if (parameters['b041'] !== undefined) {
    form['b041'] = parameters['b041']
  }
  if (parameters['b05'] !== undefined) {
    form['b05'] = parameters['b05']
  }
  if (parameters['b05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b05'))
  }
  if (parameters['b051'] !== undefined) {
    form['b051'] = parameters['b051']
  }
  if (parameters['b06'] !== undefined) {
    form['b06'] = parameters['b06']
  }
  if (parameters['b06'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b06'))
  }
  if (parameters['b061'] !== undefined) {
    form['b061'] = parameters['b061']
  }
  if (parameters['b07'] !== undefined) {
    form['b07'] = parameters['b07']
  }
  if (parameters['b07'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b07'))
  }
  if (parameters['b071'] !== undefined) {
    form['b071'] = parameters['b071']
  }
  if (parameters['b08'] !== undefined) {
    form['b08'] = parameters['b08']
  }
  if (parameters['b08'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b08'))
  }
  if (parameters['b10'] !== undefined) {
    form['b10'] = parameters['b10']
  }
  if (parameters['b10'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b10'))
  }
  if (parameters['c01'] !== undefined) {
    form['c01'] = parameters['c01']
  }
  if (parameters['c01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c01'))
  }
  if (parameters['c01Times'] !== undefined) {
    form['c01Times'] = parameters['c01Times']
  }
  if (parameters['c021'] !== undefined) {
    form['c021'] = parameters['c021']
  }
  if (parameters['c021'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c021'))
  }
  if (parameters['c022'] !== undefined) {
    form['c022'] = parameters['c022']
  }
  if (parameters['c022'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c022'))
  }
  if (parameters['c023'] !== undefined) {
    form['c023'] = parameters['c023']
  }
  if (parameters['c023'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c023'))
  }
  if (parameters['c024'] !== undefined) {
    form['c024'] = parameters['c024']
  }
  if (parameters['c024'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c024'))
  }
  if (parameters['c025'] !== undefined) {
    form['c025'] = parameters['c025']
  }
  if (parameters['c025'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c025'))
  }
  if (parameters['c026'] !== undefined) {
    form['c026'] = parameters['c026']
  }
  if (parameters['c026'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c026'))
  }
  if (parameters['c03'] !== undefined) {
    form['c03'] = parameters['c03']
  }
  if (parameters['c03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c03'))
  }
  if (parameters['c04'] !== undefined) {
    form['c04'] = parameters['c04']
  }
  if (parameters['c04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c04'))
  }
  if (parameters['c05'] !== undefined) {
    form['c05'] = parameters['c05']
  }
  if (parameters['c05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c05'))
  }
  if (parameters['c05Times'] !== undefined) {
    form['c05Times'] = parameters['c05Times']
  }
  if (parameters['c06'] !== undefined) {
    form['c06'] = parameters['c06']
  }
  if (parameters['c06'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c06'))
  }
  if (parameters['c06Times'] !== undefined) {
    form['c06Times'] = parameters['c06Times']
  }
  if (parameters['c07'] !== undefined) {
    form['c07'] = parameters['c07']
  }
  if (parameters['c07'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c07'))
  }
  if (parameters['c08'] !== undefined) {
    form['c08'] = parameters['c08']
  }
  if (parameters['c08'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c08'))
  }
  if (parameters['d01'] !== undefined) {
    form['d01'] = parameters['d01']
  }
  if (parameters['d01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d01'))
  }
  if (parameters['d01AgeOfFirst'] !== undefined) {
    form['d01AgeOfFirst'] = parameters['d01AgeOfFirst']
  }
  if (parameters['d03'] !== undefined) {
    form['d03'] = parameters['d03']
  }
  if (parameters['d03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d03'))
  }
  if (parameters['d04'] !== undefined) {
    form['d04'] = parameters['d04']
  }
  if (parameters['d04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d04'))
  }
  if (parameters['d04AgeOfFirst'] !== undefined) {
    form['d04AgeOfFirst'] = parameters['d04AgeOfFirst']
  }
  if (parameters['e01'] !== undefined) {
    form['e01'] = parameters['e01']
  }
  if (parameters['e01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: e01'))
  }
  if (parameters['e01AgeOfFirst'] !== undefined) {
    form['e01AgeOfFirst'] = parameters['e01AgeOfFirst']
  }
  if (parameters['e02'] !== undefined) {
    form['e02'] = parameters['e02']
  }
  if (parameters['e03'] !== undefined) {
    form['e03'] = parameters['e03']
  }
  if (parameters['e04'] !== undefined) {
    form['e04'] = parameters['e04']
  }
  if (parameters['e05'] !== undefined) {
    form['e05'] = parameters['e05']
  }
  if (parameters['f01'] !== undefined) {
    form['f01'] = parameters['f01']
  }
  if (parameters['f01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f01'))
  }
  if (parameters['f011'] !== undefined) {
    form['f011'] = parameters['f011']
  }
  if (parameters['f02'] !== undefined) {
    form['f02'] = parameters['f02']
  }
  if (parameters['f02'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f02'))
  }
  if (parameters['f021'] !== undefined) {
    form['f021'] = parameters['f021']
  }
  if (parameters['f05'] !== undefined) {
    form['f05'] = parameters['f05']
  }
  if (parameters['f05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f05'))
  }
  if (parameters['f06'] !== undefined) {
    form['f06'] = parameters['f06']
  }
  if (parameters['f06'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f06'))
  }
  if (parameters['f07'] !== undefined) {
    form['f07'] = parameters['f07']
  }
  if (parameters['f07'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f07'))
  }
  if (parameters['f08'] !== undefined) {
    form['f08'] = parameters['f08']
  }
  if (parameters['f08'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f08'))
  }
  if (parameters['f09'] !== undefined) {
    form['f09'] = parameters['f09']
  }
  if (parameters['f09'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f09'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const bizQuestionnaireSavePshiqFormData = function(formData) {
  const url = bizQuestionnaireSavePshiq_RAW_URL()
  const method = bizQuestionnaireSavePshiq_TYPE()
  return axios[method](url, formData)
}
export const bizQuestionnaireSavePshiq_RAW_URL = function() {
  return '/business/questionnaire/save-pshiq'
}
export const bizQuestionnaireSavePshiq_TYPE = function() {
  return 'post'
}
export const bizQuestionnaireSavePshiq_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/business/questionnaire/save-pshiq'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 保存学生视力不良及影响因素专项调查表
 * request: bizQuestionnaireSaveSpvisq
 * formData: bizQuestionnaireSaveSpvisqFormData
 * url: bizQuestionnaireSaveSpvisq_URL
 * method: bizQuestionnaireSaveSpvisq_TYPE
 * raw_url: bizQuestionnaireSaveSpvisq_RAW_URL
 * @param screeningPlanStudentIdNew - 
 * @param date - 
 * @param a03 - 
 * @param a04 - 
 * @param b01 - 
 * @param b02 - 
 * @param b03 - 
 * @param b04 - 
 * @param a04OtherDescription - 你的民族其他描述
 * @param c01 - 
 * @param c02 - 
 * @param c03 - 
 * @param c04 - 当C03不是选中选项5和6时必填，反之不填
 * @param c05 - 
 * @param c06 - 
 * @param c06LimitMinute - 当C06选中选项1时必填，反之不填
 * @param d01 - 
 * @param d02 - 
 * @param d03 - 
 * @param d04 - 
 * @param d05 - 
 * @param e01 - 
 * @param e02 - 
 * @param e03 - 
 * @param e03Time - 当E03选中选项2时必填，反之不填
 * @param e04 - 在过去一周里，你平均每天看电视、使用电脑、手机、游戏机、以及看（玩）其他电子屏幕的时间？
 * @param e04Time - 当E04选中选项2时必填，反之不填。在过去一周里，你平均每天看电视、使用电脑、手机、游戏机、以及看（玩）其他电子屏幕的平均分钟数
 * @param e04NonLearningTime - 当E04选中选项2时必填，反之不填。在过去一周里，你平均每天看电视、使用电脑、手机、游戏机、以及看（玩）其他电子屏幕的时间非学习目的使用分钟数
 * @param f01 - 
 * @param f02 - 
 * @param f03 - 
 * @param f04 - 
 * @param f05 - 
 * @param f05OtherDescription - 当F05选中选项4时必填，反之不填
 * @param f06 - 
 * @param f07 - 
 * @param f08 - 
 * @param g01 - 
 * @param g02 - 
 * @param g03 - 
 * @param h01 - 
 * @param h02 - 
 * @param h03 - 
 * @param h04 - 当H03选中选项1时必填，反之不填
 * @param h04OtherDescription - 当H04包括选项5时必填，反之不填
 * @param h05 - 当H03选中选项1时必填，反之不填
 * @param h05OtherDescription - 当H05包括选项6时必填，反之不填
 * @param h06 - 当H05不包括选项7时必填，反之不填
 * @param h06AgeOfFirst - 当H06不选中选项3时必填，反之不填
 * @param h07 - 当H05不包括选项7时必填，反之不填
 * @param h08 - 
 * @param i01 - 
 * @param i02 - 
 * @param i03 - 
 * @param i03Week - 
 * @param i04 - 
 * @param i05 - 
 * @param i06 - 
 * @param i07 - 
 * @param i08 - 
 */
export const bizQuestionnaireSaveSpvisq = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/business/questionnaire/save-spvisq'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['screeningPlanStudentIdNew'] !== undefined) {
    form['screeningPlanStudentIdNew'] = parameters['screeningPlanStudentIdNew']
  }
  if (parameters['screeningPlanStudentIdNew'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningPlanStudentIdNew'))
  }
  if (parameters['date'] !== undefined) {
    form['date'] = parameters['date']
  }
  if (parameters['date'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: date'))
  }
  if (parameters['a03'] !== undefined) {
    form['a03'] = parameters['a03']
  }
  if (parameters['a03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a03'))
  }
  if (parameters['a04'] !== undefined) {
    form['a04'] = parameters['a04']
  }
  if (parameters['a04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a04'))
  }
  if (parameters['b01'] !== undefined) {
    form['b01'] = parameters['b01']
  }
  if (parameters['b01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b01'))
  }
  if (parameters['b02'] !== undefined) {
    form['b02'] = parameters['b02']
  }
  if (parameters['b02'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b02'))
  }
  if (parameters['b03'] !== undefined) {
    form['b03'] = parameters['b03']
  }
  if (parameters['b03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b03'))
  }
  if (parameters['b04'] !== undefined) {
    form['b04'] = parameters['b04']
  }
  if (parameters['b04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b04'))
  }
  if (parameters['a04OtherDescription'] !== undefined) {
    form['a04OtherDescription'] = parameters['a04OtherDescription']
  }
  if (parameters['c01'] !== undefined) {
    form['c01'] = parameters['c01']
  }
  if (parameters['c01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c01'))
  }
  if (parameters['c02'] !== undefined) {
    form['c02'] = parameters['c02']
  }
  if (parameters['c02'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c02'))
  }
  if (parameters['c03'] !== undefined) {
    form['c03'] = parameters['c03']
  }
  if (parameters['c03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c03'))
  }
  if (parameters['c04'] !== undefined) {
    form['c04'] = parameters['c04']
  }
  if (parameters['c05'] !== undefined) {
    form['c05'] = parameters['c05']
  }
  if (parameters['c05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c05'))
  }
  if (parameters['c06'] !== undefined) {
    form['c06'] = parameters['c06']
  }
  if (parameters['c06'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c06'))
  }
  if (parameters['c06LimitMinute'] !== undefined) {
    form['c06LimitMinute'] = parameters['c06LimitMinute']
  }
  if (parameters['d01'] !== undefined) {
    form['d01'] = parameters['d01']
  }
  if (parameters['d01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d01'))
  }
  if (parameters['d02'] !== undefined) {
    form['d02'] = parameters['d02']
  }
  if (parameters['d02'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d02'))
  }
  if (parameters['d03'] !== undefined) {
    form['d03'] = parameters['d03']
  }
  if (parameters['d03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d03'))
  }
  if (parameters['d04'] !== undefined) {
    form['d04'] = parameters['d04']
  }
  if (parameters['d04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d04'))
  }
  if (parameters['d05'] !== undefined) {
    form['d05'] = parameters['d05']
  }
  if (parameters['d05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d05'))
  }
  if (parameters['e01'] !== undefined) {
    form['e01'] = parameters['e01']
  }
  if (parameters['e01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: e01'))
  }
  if (parameters['e02'] !== undefined) {
    form['e02'] = parameters['e02']
  }
  if (parameters['e02'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: e02'))
  }
  if (parameters['e03'] !== undefined) {
    form['e03'] = parameters['e03']
  }
  if (parameters['e03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: e03'))
  }
  if (parameters['e03Time'] !== undefined) {
    form['e03Time'] = parameters['e03Time']
  }
  if (parameters['e04'] !== undefined) {
    form['e04'] = parameters['e04']
  }
  if (parameters['e04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: e04'))
  }
  if (parameters['e04Time'] !== undefined) {
    form['e04Time'] = parameters['e04Time']
  }
  if (parameters['e04NonLearningTime'] !== undefined) {
    form['e04NonLearningTime'] = parameters['e04NonLearningTime']
  }
  if (parameters['f01'] !== undefined) {
    form['f01'] = parameters['f01']
  }
  if (parameters['f01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f01'))
  }
  if (parameters['f02'] !== undefined) {
    form['f02'] = parameters['f02']
  }
  if (parameters['f02'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f02'))
  }
  if (parameters['f03'] !== undefined) {
    form['f03'] = parameters['f03']
  }
  if (parameters['f03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f03'))
  }
  if (parameters['f04'] !== undefined) {
    form['f04'] = parameters['f04']
  }
  if (parameters['f04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f04'))
  }
  if (parameters['f05'] !== undefined) {
    form['f05'] = parameters['f05']
  }
  if (parameters['f05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f05'))
  }
  if (parameters['f05OtherDescription'] !== undefined) {
    form['f05OtherDescription'] = parameters['f05OtherDescription']
  }
  if (parameters['f06'] !== undefined) {
    form['f06'] = parameters['f06']
  }
  if (parameters['f06'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f06'))
  }
  if (parameters['f07'] !== undefined) {
    form['f07'] = parameters['f07']
  }
  if (parameters['f07'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f07'))
  }
  if (parameters['f08'] !== undefined) {
    form['f08'] = parameters['f08']
  }
  if (parameters['f08'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f08'))
  }
  if (parameters['g01'] !== undefined) {
    form['g01'] = parameters['g01']
  }
  if (parameters['g01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: g01'))
  }
  if (parameters['g02'] !== undefined) {
    form['g02'] = parameters['g02']
  }
  if (parameters['g02'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: g02'))
  }
  if (parameters['g03'] !== undefined) {
    form['g03'] = parameters['g03']
  }
  if (parameters['g03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: g03'))
  }
  if (parameters['h01'] !== undefined) {
    form['h01'] = parameters['h01']
  }
  if (parameters['h01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h01'))
  }
  if (parameters['h02'] !== undefined) {
    form['h02'] = parameters['h02']
  }
  if (parameters['h02'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h02'))
  }
  if (parameters['h03'] !== undefined) {
    form['h03'] = parameters['h03']
  }
  if (parameters['h03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h03'))
  }
  if (parameters['h04'] !== undefined) {
    form['h04'] = parameters['h04']
  }
  if (parameters['h04OtherDescription'] !== undefined) {
    form['h04OtherDescription'] = parameters['h04OtherDescription']
  }
  if (parameters['h05'] !== undefined) {
    form['h05'] = parameters['h05']
  }
  if (parameters['h05OtherDescription'] !== undefined) {
    form['h05OtherDescription'] = parameters['h05OtherDescription']
  }
  if (parameters['h06'] !== undefined) {
    form['h06'] = parameters['h06']
  }
  if (parameters['h06AgeOfFirst'] !== undefined) {
    form['h06AgeOfFirst'] = parameters['h06AgeOfFirst']
  }
  if (parameters['h07'] !== undefined) {
    form['h07'] = parameters['h07']
  }
  if (parameters['h08'] !== undefined) {
    form['h08'] = parameters['h08']
  }
  if (parameters['i01'] !== undefined) {
    form['i01'] = parameters['i01']
  }
  if (parameters['i01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: i01'))
  }
  if (parameters['i02'] !== undefined) {
    form['i02'] = parameters['i02']
  }
  if (parameters['i02'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: i02'))
  }
  if (parameters['i03'] !== undefined) {
    form['i03'] = parameters['i03']
  }
  if (parameters['i03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: i03'))
  }
  if (parameters['i03Week'] !== undefined) {
    form['i03Week'] = parameters['i03Week']
  }
  if (parameters['i04'] !== undefined) {
    form['i04'] = parameters['i04']
  }
  if (parameters['i04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: i04'))
  }
  if (parameters['i05'] !== undefined) {
    form['i05'] = parameters['i05']
  }
  if (parameters['i05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: i05'))
  }
  if (parameters['i06'] !== undefined) {
    form['i06'] = parameters['i06']
  }
  if (parameters['i06'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: i06'))
  }
  if (parameters['i07'] !== undefined) {
    form['i07'] = parameters['i07']
  }
  if (parameters['i07'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: i07'))
  }
  if (parameters['i08'] !== undefined) {
    form['i08'] = parameters['i08']
  }
  if (parameters['i08'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: i08'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const bizQuestionnaireSaveSpvisqFormData = function(formData) {
  const url = bizQuestionnaireSaveSpvisq_RAW_URL()
  const method = bizQuestionnaireSaveSpvisq_TYPE()
  return axios[method](url, formData)
}
export const bizQuestionnaireSaveSpvisq_RAW_URL = function() {
  return '/business/questionnaire/save-spvisq'
}
export const bizQuestionnaireSaveSpvisq_TYPE = function() {
  return 'post'
}
export const bizQuestionnaireSaveSpvisq_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/business/questionnaire/save-spvisq'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 保存大学学生健康状况及影响因素调查表
 * request: bizQuestionnaireSaveUshiq
 * formData: bizQuestionnaireSaveUshiqFormData
 * url: bizQuestionnaireSaveUshiq_URL
 * method: bizQuestionnaireSaveUshiq_TYPE
 * raw_url: bizQuestionnaireSaveUshiq_RAW_URL
 * @param screeningPlanStudentIdNew - 
 * @param date - 
 * @param a03 - 是否住校
 * @param a04 - 你的民族
 * @param a04OtherDescription - 你的民族其他描述
 * @param a05 - 近半年，和你一起生活的家庭成员人数 近半年，和你一起生活的家庭成员人数
 * @param a051 - 当A05的值大于0时必填，反之不填,他们分别包括（可多选）
 * @param a052 - 当A05的值大于0时必填，反之不填, 家庭中同住人员最常说的话是（可多选）
 * @param a052Other - 当A052选择其他必填，反之不填,家庭中同住人员最常说的话是其他备注
 * @param a07 - 最近两周内，你是否因生病请假？
 * @param a07Day - 当A07的值为1时必填，反之不填,最近两周内，你因生病请假天数
 * @param a08 - 过去一年内，你是否因生病休学？
 * @param a08Month - 过去一年内，你是否因生病休学几个月
 * @param a09 - 是否来月经/遗精？
 * @param a091 - 首次月经/遗精的年龄
 * @param b01 - 你喝过几次含糖饮料？（如可乐、冰红茶、果粒橙、营养快线等）
 * @param b02 - 你吃过几次甜食？（包括糖果、蛋糕、巧克力、甜汤等）
 * @param b03 - 你吃过几次油炸食物？（如油条、油饼、炸薯条、炸鸡翅等）
 * @param b04 - 你吃过几次新鲜水果（不包括罐水果罐头）？
 * @param b041 - 过去7天里，你通常每天吃几种新鲜水果？
 * @param b05 - 吃过几次蔬菜？（生熟均算，如沙拉、生吃或经烹饪后）
 * @param b051 - 过去7天里，你通常每天吃几种蔬菜？
 * @param b06 - 是否每天吃早餐？
 * @param b061 - 过去7天里，你每天吃早餐种类（可多选）？
 * @param b07 - 你有几天能做到每至少 你有几天能做到每至少60分钟及以上中高强度运动（可累计）？（中高强度运动是指让你气喘吁或者心跳加快的运动。如跑步、篮球、足球、游泳、健身房内跳健身操、搬重物等）
 * @param b071 - 周末或者节假日，你能做到每天至少 60 分钟及以上中高强度运动（可累计）？
 * @param b08Minute - 当B08的值为1时必填，反之不填
 * @param b10 - 过去7天里，你通常每天喝牛奶、酸奶、豆浆或豆奶的天数？
 * @param c01 - 过去12个月里，你是否曾经受到严重伤害（严重伤害指由于伤害而需要到医院治 疗，或不能上学或影响日常活动一天及以上）？
 * @param c01Times - 当C01的值为1时必填，反之不填,过去12个月里，你曾经受到严重伤害次数？
 * @param c021 - 被恶意取笑
 * @param c022 - 被索要财物
 * @param c023 - 被有意排斥在集体活动之外或被孤立
 * @param c024 - 被威胁、恐吓
 * @param c025 - 被打、踢、推、挤或关在屋里
 * @param c026 - 因为身体缺陷或长相而被取笑
 * @param c05 - 过去12个月里，你是否去过没有安全措施（即没有专门人员管理）的地方游泳？
 * @param c05Times - 当C05的值为2时必填，反之不填.过去12个月里，你是否去过没有安全措施（即没有专门人员管理）的地方游泳？
 * @param c06 - 过去12个月里，你是否有过自杀的想法吗？
 * @param c061 - 过去12个月里，你是否有过自杀的想法吗 认真考虑过
 * @param c062 - 过去12个月里，你是否有过自杀的想法吗 考虑过，并做过如何自杀的计划
 * @param c063 - 过去12个月里，你是否有过自杀的想法吗 曾采取措施自杀
 * @param c064 - 过去12个月里，你是否有过自杀的想法吗 曾多次采取措施自杀
 * @param c07 - 过去12个月里，你是否曾连续2周或更长时间因为伤心绝望而对日常活动失去兴趣？
 * @param c08 - 过去12个月里，你是否有意伤害过自己(如用烟头烫、用刀片割伤、用头撞墙等)？
 * @param c121 - 在公共网页上受到他人的辱骂？
 * @param c122 - 曾收到侮辱性的信息？
 * @param c123 - 有人将令我尴尬的照片或视频上传到网络上？
 * @param c124 - 某人用假的身份给我发送令我难堪的信息？
 * @param c125 - 有人在网络上散布我的谣言(如社交网站等)？
 * @param c126 - 曾有人未经我的允许转发或公开我的私人信息？
 * @param c127 - 曾经受到威胁性或恐吓性的信息？
 * @param d01 - 你是否吸过烟，即使只吸过一两口？
 * @param d01AgeOfFirst - 当D01的值为1时必填，反之不填.第一次尝试吸烟的年龄？
 * @param d02 - 当D01的值为1时必填，反之不填.过去30天里，你吸过烟吗？
 * @param d02Day - 当D02的值为2时必填，反之不填.过去30天里吸过烟的天数？
 * @param d03 - 当D01的值为1时必填，反之不填,过去30天里,你通常每天吸多少支烟?
 * @param d04 - 过去7天里，是否有人当着你的面吸烟？
 * @param d05 - 你是否喝过一整杯酒？（相当于一听啤酒，一小盅白酒，一杯葡萄酒或黄酒）
 * @param d05AgeOfFirst - 当D05的值为1时必填，反之不填.第一次喝酒的年龄？
 * @param d06 - 过去30天里，有多少天你至少喝过一杯酒？
 * @param d07 - 过去30天里，有多少天你在一两个小时内至少喝下5杯酒？
 * @param d08 - 过去12个月里，你曾有几次因喝酒太多而出现头晕、头疼、嗜睡等醉酒症状？
 * @param d09 - 到目前为止，你是否使用过下列物质（没有医生许可）（可多选）
 * @param d09Description - 到目前为止，你是否使用过下列物质其它说明
 * @param d10 - 到目前为止，你是否为了高兴而吸入胶水、汽油等可吸入溶剂？
 * @param d11 - 到目前为止，除治疗咳嗽外，你是否使用过止咳药水？
 * @param d111 - 每次服用止咳药水的量是多少？
 * @param d12 - 到目前为止，在没有医生许可情况下，你是否使用镇静催眠类药品？
 * @param e01 - 从来没有上过网
 * @param e011 - 当E01的值为2时必填，反之不填.过去7天，每天上网时间小时数？
 * @param e021 - 当E01的值为2时必填，反之不填.经常上网，即使不上网，脑中也一直浮现与网络有关的事情
 * @param e022 - 当E01的值为2时必填，反之不填.一旦不能上网，就感到不舒服或不愿意干别的事，而上网则缓解
 * @param e023 - 当E01的值为2时必填，反之不填.为得到满足感增加上网时间
 * @param e024 - 当E01的值为2时必填，反之不填.因为上网而对其他娱乐活动（爱好、会见朋友）失去了兴趣
 * @param e025 - 当E01的值为2时必填，反之不填.多次想停止上网，但总不能控制自己
 * @param e026 - 当E01的值为2时必填，反之不填.因为上网而不能完成作业或逃学
 * @param e027 - 当E01的值为2时必填，反之不填.向家长或老师、同学隐瞒自己上网的事实
 * @param e028 - 当E01的值为2时必填，反之不填.明知负面后果（睡眠不足、上课迟到、与父母争执）而继续上网
 * @param e029 - 当E01的值为2时必填，反之不填.为了逃避现实、摆脱自己的困境或郁闷、无助、焦虑情绪才上网
 * @param f01 - 在过去一周里，你平均每天看电视（包括电视游戏如X-BOX）多长时间？
 * @param f02 - 在过去一周里，通常你每天用电脑的时间？
 * @param f03 - 在过去一周里，你用移动电子设备（包括手机、掌上游戏机、平板等）多长时间？
 * @param f03Time - 当F03的值为2时必填，反之不填.在过去一周里，你用移动电子设备（包括手机、掌上游戏机、平板等）平均分钟数
 * @param f06 - 在过去一周里，你平均每天看电视、使用电脑、手机、游戏机、以及看（玩）其他电子屏幕的时间？
 * @param f06Time - 当F06的值为2时必填，反之不填.在过去一周里，你平均每天看电视、使用电脑、手机、游戏机、以及看（玩）其他电子屏幕的平均分钟数
 * @param f04 - 过去一周里，你每天白天户外活动时间是多少？
 * @param f05 - 你每天的睡眠时间为？（分钟数）
 * @param g01 - 不戴眼镜的情况下，你是否有一只或两只眼睛的视力低于5.0？
 * @param h01 - 你是否使用耳机（至少连续30分钟）
 * @param h01AgeOfFirst - 当H01的值为2时必填，反之不填.你第一次使用耳机的岁数
 * @param h02 - 当H01的值为2时必填，反之不填.过去7天，你平均每天使用耳机分钟数 --> 时间选项
 * @param h03 - 当H01的值为2时必填，反之不填.过去7天，你连续使用耳机超过60分钟的次数
 * @param h04 - 当H01的值为2时必填，反之不填.过去7天，你是否在嘈杂的环境中（如车站、地铁、商场等需要你调高声量地方）长时间使用耳机(至少连续30分钟)
 * @param h05 - 当H01的值为2时必填，反之不填.最近1个月，你是否感觉到自己听声音不如过去清楚了
 * @param i01 - 你在学校接受过有关青春期知识的教育吗？
 * @param i02 - 你曾经发生过性行为吗？
 * @param i03 - 第一次发生性行为时，你多大年龄？ (不包括接吻、拥抱)
 * @param i04 - 当I03的值不为1时必填，反之不填.第一次发生性行为时，你或者对方是否使用了安全套？
 * @param i05 - 当I03的值不为1时必填，反之不填.到目前为止，你曾与几个人发生过性行为？
 * @param i06 - 当I03的值不为1时必填，反之不填.你在最近一次性行为之前有没有饮酒或使用成瘾性物质？
 * @param i07 - 当I03的值不为1时必填，反之不填.最近一次发生性行为时，你或者对方是否使用了安全套？
 * @param i08 - 当I03的值不为1时必填，反之不填.最近一次发生性行为时，你或者对方是否使用了紧急避孕药？
 * @param i09 - 当I03的值不为1时必填，反之不填.你是否曾经怀孕，或者使他人怀孕过？
 * @param j01 - 平常并不困扰我的事现在使我心烦
 * @param j02 - 我不想吃东西；我胃口不好
 * @param j03 - 我觉得即便有家人或朋友帮助也无法摆脱这种苦闷
 * @param j04 - 我同别人一样感觉良好
 * @param j05 - 我很难集中精力做事
 * @param j06 - 我感到压抑
 * @param j07 - 我感到做什么事都很费力
 * @param j08 - 我觉得未来有希望
 * @param j09 - 我认为我的生活一无是处
 * @param j10 - 我感到恐惧
 * @param j11 - 我睡觉后不能缓解疲劳
 * @param j12 - 我很幸福
 * @param j13 - 我比平时话少了
 * @param j14 - 我感到孤独
 * @param j15 - 人们对我不友好
 * @param j16 - 我生活快乐
 * @param j17 - 我曾经放声痛哭
 * @param j18 - 我感到忧愁
 * @param j19 - 我觉得别人厌恶我
 * @param j20 - 我觉得无法继续我日常的生活学习
 * @param j21 - 感觉紧张，焦虑或急切
 * @param j22 - 不能够停止或控制担忧
 * @param j23 - 对各种恶样的事情担忧过多
 * @param j24 - 很难放松下来
 * @param j25 - 由于不安而无法静坐
 * @param j26 - 变得容易烦恼或急躁
 * @param j27 - 感到似乎将有可怕的事情发生而害怕
 * @param j28 - 担你被心理问题困扰时，你是否寻求过外界的帮助？
 * @param j281 - 你寻求了什么形式的帮助
 * @param j282 - 寻求帮助后你的心理问题是否得到有效缓解？
 * @param l01 - 你平时背书包的习惯是？
 * @param l02 - 对你来说，书包的重量如何?
 * @param l03 - 你所在班级的座位是否进行左右轮转？
 * @param l03Week - 每几周轮转一次
 * @param l04 - 你认为自己的床垫（床）柔软度如何？
 * @param l05 - 你的左右脚鞋底磨损情况？
 * @param l06 - 你鞋底内外侧磨损不同的情况？
 * @param l07 - 平时对坐姿、站姿的自我要求是？
 * @param l08 - 过去1个月内，你身体的以下部位出现过持续酸痛？（可多选）
 */
export const bizQuestionnaireSaveUshiq = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/business/questionnaire/save-ushiq'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['screeningPlanStudentIdNew'] !== undefined) {
    form['screeningPlanStudentIdNew'] = parameters['screeningPlanStudentIdNew']
  }
  if (parameters['screeningPlanStudentIdNew'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningPlanStudentIdNew'))
  }
  if (parameters['date'] !== undefined) {
    form['date'] = parameters['date']
  }
  if (parameters['date'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: date'))
  }
  if (parameters['a03'] !== undefined) {
    form['a03'] = parameters['a03']
  }
  if (parameters['a03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a03'))
  }
  if (parameters['a04'] !== undefined) {
    form['a04'] = parameters['a04']
  }
  if (parameters['a04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a04'))
  }
  if (parameters['a04OtherDescription'] !== undefined) {
    form['a04OtherDescription'] = parameters['a04OtherDescription']
  }
  if (parameters['a05'] !== undefined) {
    form['a05'] = parameters['a05']
  }
  if (parameters['a05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a05'))
  }
  if (parameters['a051'] !== undefined) {
    form['a051'] = parameters['a051']
  }
  if (parameters['a052'] !== undefined) {
    form['a052'] = parameters['a052']
  }
  if (parameters['a052Other'] !== undefined) {
    form['a052Other'] = parameters['a052Other']
  }
  if (parameters['a07'] !== undefined) {
    form['a07'] = parameters['a07']
  }
  if (parameters['a07'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a07'))
  }
  if (parameters['a07Day'] !== undefined) {
    form['a07Day'] = parameters['a07Day']
  }
  if (parameters['a08'] !== undefined) {
    form['a08'] = parameters['a08']
  }
  if (parameters['a08'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a08'))
  }
  if (parameters['a08Month'] !== undefined) {
    form['a08Month'] = parameters['a08Month']
  }
  if (parameters['a09'] !== undefined) {
    form['a09'] = parameters['a09']
  }
  if (parameters['a091'] !== undefined) {
    form['a091'] = parameters['a091']
  }
  if (parameters['b01'] !== undefined) {
    form['b01'] = parameters['b01']
  }
  if (parameters['b01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b01'))
  }
  if (parameters['b02'] !== undefined) {
    form['b02'] = parameters['b02']
  }
  if (parameters['b03'] !== undefined) {
    form['b03'] = parameters['b03']
  }
  if (parameters['b03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b03'))
  }
  if (parameters['b04'] !== undefined) {
    form['b04'] = parameters['b04']
  }
  if (parameters['b04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b04'))
  }
  if (parameters['b041'] !== undefined) {
    form['b041'] = parameters['b041']
  }
  if (parameters['b05'] !== undefined) {
    form['b05'] = parameters['b05']
  }
  if (parameters['b05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b05'))
  }
  if (parameters['b051'] !== undefined) {
    form['b051'] = parameters['b051']
  }
  if (parameters['b06'] !== undefined) {
    form['b06'] = parameters['b06']
  }
  if (parameters['b06'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b06'))
  }
  if (parameters['b061'] !== undefined) {
    form['b061'] = parameters['b061']
  }
  if (parameters['b07'] !== undefined) {
    form['b07'] = parameters['b07']
  }
  if (parameters['b07'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b07'))
  }
  if (parameters['b071'] !== undefined) {
    form['b071'] = parameters['b071']
  }
  if (parameters['b08Minute'] !== undefined) {
    form['b08Minute'] = parameters['b08Minute']
  }
  if (parameters['b10'] !== undefined) {
    form['b10'] = parameters['b10']
  }
  if (parameters['b10'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b10'))
  }
  if (parameters['c01'] !== undefined) {
    form['c01'] = parameters['c01']
  }
  if (parameters['c01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c01'))
  }
  if (parameters['c01Times'] !== undefined) {
    form['c01Times'] = parameters['c01Times']
  }
  if (parameters['c021'] !== undefined) {
    form['c021'] = parameters['c021']
  }
  if (parameters['c021'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c021'))
  }
  if (parameters['c022'] !== undefined) {
    form['c022'] = parameters['c022']
  }
  if (parameters['c022'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c022'))
  }
  if (parameters['c023'] !== undefined) {
    form['c023'] = parameters['c023']
  }
  if (parameters['c023'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c023'))
  }
  if (parameters['c024'] !== undefined) {
    form['c024'] = parameters['c024']
  }
  if (parameters['c024'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c024'))
  }
  if (parameters['c025'] !== undefined) {
    form['c025'] = parameters['c025']
  }
  if (parameters['c025'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c025'))
  }
  if (parameters['c026'] !== undefined) {
    form['c026'] = parameters['c026']
  }
  if (parameters['c026'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c026'))
  }
  if (parameters['c05'] !== undefined) {
    form['c05'] = parameters['c05']
  }
  if (parameters['c05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c05'))
  }
  if (parameters['c05Times'] !== undefined) {
    form['c05Times'] = parameters['c05Times']
  }
  if (parameters['c06'] !== undefined) {
    form['c06'] = parameters['c06']
  }
  if (parameters['c061'] !== undefined) {
    form['c061'] = parameters['c061']
  }
  if (parameters['c061'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c061'))
  }
  if (parameters['c062'] !== undefined) {
    form['c062'] = parameters['c062']
  }
  if (parameters['c062'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c062'))
  }
  if (parameters['c063'] !== undefined) {
    form['c063'] = parameters['c063']
  }
  if (parameters['c063'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c063'))
  }
  if (parameters['c064'] !== undefined) {
    form['c064'] = parameters['c064']
  }
  if (parameters['c064'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c064'))
  }
  if (parameters['c07'] !== undefined) {
    form['c07'] = parameters['c07']
  }
  if (parameters['c07'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c07'))
  }
  if (parameters['c08'] !== undefined) {
    form['c08'] = parameters['c08']
  }
  if (parameters['c08'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c08'))
  }
  if (parameters['c121'] !== undefined) {
    form['c121'] = parameters['c121']
  }
  if (parameters['c121'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c121'))
  }
  if (parameters['c122'] !== undefined) {
    form['c122'] = parameters['c122']
  }
  if (parameters['c122'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c122'))
  }
  if (parameters['c123'] !== undefined) {
    form['c123'] = parameters['c123']
  }
  if (parameters['c123'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c123'))
  }
  if (parameters['c124'] !== undefined) {
    form['c124'] = parameters['c124']
  }
  if (parameters['c124'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c124'))
  }
  if (parameters['c125'] !== undefined) {
    form['c125'] = parameters['c125']
  }
  if (parameters['c125'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c125'))
  }
  if (parameters['c126'] !== undefined) {
    form['c126'] = parameters['c126']
  }
  if (parameters['c126'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c126'))
  }
  if (parameters['c127'] !== undefined) {
    form['c127'] = parameters['c127']
  }
  if (parameters['c127'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c127'))
  }
  if (parameters['d01'] !== undefined) {
    form['d01'] = parameters['d01']
  }
  if (parameters['d01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d01'))
  }
  if (parameters['d01AgeOfFirst'] !== undefined) {
    form['d01AgeOfFirst'] = parameters['d01AgeOfFirst']
  }
  if (parameters['d02'] !== undefined) {
    form['d02'] = parameters['d02']
  }
  if (parameters['d02Day'] !== undefined) {
    form['d02Day'] = parameters['d02Day']
  }
  if (parameters['d03'] !== undefined) {
    form['d03'] = parameters['d03']
  }
  if (parameters['d04'] !== undefined) {
    form['d04'] = parameters['d04']
  }
  if (parameters['d04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d04'))
  }
  if (parameters['d05'] !== undefined) {
    form['d05'] = parameters['d05']
  }
  if (parameters['d05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d05'))
  }
  if (parameters['d05AgeOfFirst'] !== undefined) {
    form['d05AgeOfFirst'] = parameters['d05AgeOfFirst']
  }
  if (parameters['d06'] !== undefined) {
    form['d06'] = parameters['d06']
  }
  if (parameters['d07'] !== undefined) {
    form['d07'] = parameters['d07']
  }
  if (parameters['d08'] !== undefined) {
    form['d08'] = parameters['d08']
  }
  if (parameters['d09'] !== undefined) {
    form['d09'] = parameters['d09']
  }
  if (parameters['d09'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d09'))
  }
  if (parameters['d09Description'] !== undefined) {
    form['d09Description'] = parameters['d09Description']
  }
  if (parameters['d10'] !== undefined) {
    form['d10'] = parameters['d10']
  }
  if (parameters['d10'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d10'))
  }
  if (parameters['d11'] !== undefined) {
    form['d11'] = parameters['d11']
  }
  if (parameters['d11'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d11'))
  }
  if (parameters['d111'] !== undefined) {
    form['d111'] = parameters['d111']
  }
  if (parameters['d12'] !== undefined) {
    form['d12'] = parameters['d12']
  }
  if (parameters['d12'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d12'))
  }
  if (parameters['e01'] !== undefined) {
    form['e01'] = parameters['e01']
  }
  if (parameters['e01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: e01'))
  }
  if (parameters['e011'] !== undefined) {
    form['e011'] = parameters['e011']
  }
  if (parameters['e021'] !== undefined) {
    form['e021'] = parameters['e021']
  }
  if (parameters['e022'] !== undefined) {
    form['e022'] = parameters['e022']
  }
  if (parameters['e023'] !== undefined) {
    form['e023'] = parameters['e023']
  }
  if (parameters['e024'] !== undefined) {
    form['e024'] = parameters['e024']
  }
  if (parameters['e025'] !== undefined) {
    form['e025'] = parameters['e025']
  }
  if (parameters['e026'] !== undefined) {
    form['e026'] = parameters['e026']
  }
  if (parameters['e027'] !== undefined) {
    form['e027'] = parameters['e027']
  }
  if (parameters['e028'] !== undefined) {
    form['e028'] = parameters['e028']
  }
  if (parameters['e029'] !== undefined) {
    form['e029'] = parameters['e029']
  }
  if (parameters['f01'] !== undefined) {
    form['f01'] = parameters['f01']
  }
  if (parameters['f01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f01'))
  }
  if (parameters['f02'] !== undefined) {
    form['f02'] = parameters['f02']
  }
  if (parameters['f02'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f02'))
  }
  if (parameters['f03'] !== undefined) {
    form['f03'] = parameters['f03']
  }
  if (parameters['f03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f03'))
  }
  if (parameters['f03Time'] !== undefined) {
    form['f03Time'] = parameters['f03Time']
  }
  if (parameters['f06'] !== undefined) {
    form['f06'] = parameters['f06']
  }
  if (parameters['f06'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f06'))
  }
  if (parameters['f06Time'] !== undefined) {
    form['f06Time'] = parameters['f06Time']
  }
  if (parameters['f04'] !== undefined) {
    form['f04'] = parameters['f04']
  }
  if (parameters['f04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f04'))
  }
  if (parameters['f05'] !== undefined) {
    form['f05'] = parameters['f05']
  }
  if (parameters['f05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f05'))
  }
  if (parameters['g01'] !== undefined) {
    form['g01'] = parameters['g01']
  }
  if (parameters['g01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: g01'))
  }
  if (parameters['h01'] !== undefined) {
    form['h01'] = parameters['h01']
  }
  if (parameters['h01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h01'))
  }
  if (parameters['h01AgeOfFirst'] !== undefined) {
    form['h01AgeOfFirst'] = parameters['h01AgeOfFirst']
  }
  if (parameters['h02'] !== undefined) {
    form['h02'] = parameters['h02']
  }
  if (parameters['h03'] !== undefined) {
    form['h03'] = parameters['h03']
  }
  if (parameters['h04'] !== undefined) {
    form['h04'] = parameters['h04']
  }
  if (parameters['h05'] !== undefined) {
    form['h05'] = parameters['h05']
  }
  if (parameters['i01'] !== undefined) {
    form['i01'] = parameters['i01']
  }
  if (parameters['i01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: i01'))
  }
  if (parameters['i02'] !== undefined) {
    form['i02'] = parameters['i02']
  }
  if (parameters['i02'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: i02'))
  }
  if (parameters['i03'] !== undefined) {
    form['i03'] = parameters['i03']
  }
  if (parameters['i03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: i03'))
  }
  if (parameters['i04'] !== undefined) {
    form['i04'] = parameters['i04']
  }
  if (parameters['i05'] !== undefined) {
    form['i05'] = parameters['i05']
  }
  if (parameters['i06'] !== undefined) {
    form['i06'] = parameters['i06']
  }
  if (parameters['i07'] !== undefined) {
    form['i07'] = parameters['i07']
  }
  if (parameters['i08'] !== undefined) {
    form['i08'] = parameters['i08']
  }
  if (parameters['i09'] !== undefined) {
    form['i09'] = parameters['i09']
  }
  if (parameters['j01'] !== undefined) {
    form['j01'] = parameters['j01']
  }
  if (parameters['j01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j01'))
  }
  if (parameters['j02'] !== undefined) {
    form['j02'] = parameters['j02']
  }
  if (parameters['j02'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j02'))
  }
  if (parameters['j03'] !== undefined) {
    form['j03'] = parameters['j03']
  }
  if (parameters['j03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j03'))
  }
  if (parameters['j04'] !== undefined) {
    form['j04'] = parameters['j04']
  }
  if (parameters['j04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j04'))
  }
  if (parameters['j05'] !== undefined) {
    form['j05'] = parameters['j05']
  }
  if (parameters['j05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j05'))
  }
  if (parameters['j06'] !== undefined) {
    form['j06'] = parameters['j06']
  }
  if (parameters['j06'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j06'))
  }
  if (parameters['j07'] !== undefined) {
    form['j07'] = parameters['j07']
  }
  if (parameters['j07'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j07'))
  }
  if (parameters['j08'] !== undefined) {
    form['j08'] = parameters['j08']
  }
  if (parameters['j08'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j08'))
  }
  if (parameters['j09'] !== undefined) {
    form['j09'] = parameters['j09']
  }
  if (parameters['j09'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j09'))
  }
  if (parameters['j10'] !== undefined) {
    form['j10'] = parameters['j10']
  }
  if (parameters['j10'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j10'))
  }
  if (parameters['j11'] !== undefined) {
    form['j11'] = parameters['j11']
  }
  if (parameters['j11'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j11'))
  }
  if (parameters['j12'] !== undefined) {
    form['j12'] = parameters['j12']
  }
  if (parameters['j12'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j12'))
  }
  if (parameters['j13'] !== undefined) {
    form['j13'] = parameters['j13']
  }
  if (parameters['j13'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j13'))
  }
  if (parameters['j14'] !== undefined) {
    form['j14'] = parameters['j14']
  }
  if (parameters['j14'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j14'))
  }
  if (parameters['j15'] !== undefined) {
    form['j15'] = parameters['j15']
  }
  if (parameters['j15'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j15'))
  }
  if (parameters['j16'] !== undefined) {
    form['j16'] = parameters['j16']
  }
  if (parameters['j16'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j16'))
  }
  if (parameters['j17'] !== undefined) {
    form['j17'] = parameters['j17']
  }
  if (parameters['j17'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j17'))
  }
  if (parameters['j18'] !== undefined) {
    form['j18'] = parameters['j18']
  }
  if (parameters['j18'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j18'))
  }
  if (parameters['j19'] !== undefined) {
    form['j19'] = parameters['j19']
  }
  if (parameters['j19'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j19'))
  }
  if (parameters['j20'] !== undefined) {
    form['j20'] = parameters['j20']
  }
  if (parameters['j20'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j20'))
  }
  if (parameters['j21'] !== undefined) {
    form['j21'] = parameters['j21']
  }
  if (parameters['j21'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j21'))
  }
  if (parameters['j22'] !== undefined) {
    form['j22'] = parameters['j22']
  }
  if (parameters['j22'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j22'))
  }
  if (parameters['j23'] !== undefined) {
    form['j23'] = parameters['j23']
  }
  if (parameters['j23'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j23'))
  }
  if (parameters['j24'] !== undefined) {
    form['j24'] = parameters['j24']
  }
  if (parameters['j24'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j24'))
  }
  if (parameters['j25'] !== undefined) {
    form['j25'] = parameters['j25']
  }
  if (parameters['j25'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j25'))
  }
  if (parameters['j26'] !== undefined) {
    form['j26'] = parameters['j26']
  }
  if (parameters['j26'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j26'))
  }
  if (parameters['j27'] !== undefined) {
    form['j27'] = parameters['j27']
  }
  if (parameters['j27'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j27'))
  }
  if (parameters['j28'] !== undefined) {
    form['j28'] = parameters['j28']
  }
  if (parameters['j28'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j28'))
  }
  if (parameters['j281'] !== undefined) {
    form['j281'] = parameters['j281']
  }
  if (parameters['j282'] !== undefined) {
    form['j282'] = parameters['j282']
  }
  if (parameters['l01'] !== undefined) {
    form['l01'] = parameters['l01']
  }
  if (parameters['l01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: l01'))
  }
  if (parameters['l02'] !== undefined) {
    form['l02'] = parameters['l02']
  }
  if (parameters['l02'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: l02'))
  }
  if (parameters['l03'] !== undefined) {
    form['l03'] = parameters['l03']
  }
  if (parameters['l03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: l03'))
  }
  if (parameters['l03Week'] !== undefined) {
    form['l03Week'] = parameters['l03Week']
  }
  if (parameters['l04'] !== undefined) {
    form['l04'] = parameters['l04']
  }
  if (parameters['l04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: l04'))
  }
  if (parameters['l05'] !== undefined) {
    form['l05'] = parameters['l05']
  }
  if (parameters['l05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: l05'))
  }
  if (parameters['l06'] !== undefined) {
    form['l06'] = parameters['l06']
  }
  if (parameters['l06'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: l06'))
  }
  if (parameters['l07'] !== undefined) {
    form['l07'] = parameters['l07']
  }
  if (parameters['l07'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: l07'))
  }
  if (parameters['l08'] !== undefined) {
    form['l08'] = parameters['l08']
  }
  if (parameters['l08'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: l08'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const bizQuestionnaireSaveUshiqFormData = function(formData) {
  const url = bizQuestionnaireSaveUshiq_RAW_URL()
  const method = bizQuestionnaireSaveUshiq_TYPE()
  return axios[method](url, formData)
}
export const bizQuestionnaireSaveUshiq_RAW_URL = function() {
  return '/business/questionnaire/save-ushiq'
}
export const bizQuestionnaireSaveUshiq_TYPE = function() {
  return 'post'
}
export const bizQuestionnaireSaveUshiq_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/business/questionnaire/save-ushiq'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 筛查计划列表
 * request: bizScreeningPlanList
 * formData: bizScreeningPlanListFormData
 * url: bizScreeningPlanList_URL
 * method: bizScreeningPlanList_TYPE
 * raw_url: bizScreeningPlanList_RAW_URL
 */
export const bizScreeningPlanList = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/business/questionnaire/screening-plan-list'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const bizScreeningPlanListFormData = function(formData) {
  const url = bizScreeningPlanList_RAW_URL()
  const method = bizScreeningPlanList_TYPE()
  return axios[method](url, formData)
}
export const bizScreeningPlanList_RAW_URL = function() {
  return '/business/questionnaire/screening-plan-list'
}
export const bizScreeningPlanList_TYPE = function() {
  return 'get'
}
export const bizScreeningPlanList_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/business/questionnaire/screening-plan-list'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 学生问卷信息
 * request: bizQuestionnaire
 * formData: bizQuestionnaireFormData
 * url: bizQuestionnaire_URL
 * method: bizQuestionnaire_TYPE
 * raw_url: bizQuestionnaire_RAW_URL
 * @param planId - planId
 * @param name - 学生姓名
 * @param idCard - 身份证号
 */
export const bizQuestionnaire = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/business/questionnaire/{planId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{planId}', `${parameters['planId']}`)
  if (parameters['planId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: planId'))
  }
  if (parameters['name'] !== undefined) {
    form['name'] = parameters['name']
  }
  if (parameters['name'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: name'))
  }
  if (parameters['idCard'] !== undefined) {
    form['idCard'] = parameters['idCard']
  }
  if (parameters['idCard'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: idCard'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const bizQuestionnaireFormData = function(formData) {
  const url = bizQuestionnaire_RAW_URL()
  const method = bizQuestionnaire_TYPE()
  return axios[method](url, formData)
}
export const bizQuestionnaire_RAW_URL = function() {
  return '/business/questionnaire/{planId}'
}
export const bizQuestionnaire_TYPE = function() {
  return 'post'
}
export const bizQuestionnaire_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/business/questionnaire/{planId}'
  path = path.replace('{planId}', `${parameters['planId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 用户筛查记录列表
 * request: bizUserScreeningRecordList
 * formData: bizUserScreeningRecordListFormData
 * url: bizUserScreeningRecordList_URL
 * method: bizUserScreeningRecordList_TYPE
 * raw_url: bizUserScreeningRecordList_RAW_URL
 */
export const bizUserScreeningRecordList = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/business/user-screening-record'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const bizUserScreeningRecordListFormData = function(formData) {
  const url = bizUserScreeningRecordList_RAW_URL()
  const method = bizUserScreeningRecordList_TYPE()
  return axios[method](url, formData)
}
export const bizUserScreeningRecordList_RAW_URL = function() {
  return '/business/user-screening-record'
}
export const bizUserScreeningRecordList_TYPE = function() {
  return 'get'
}
export const bizUserScreeningRecordList_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/business/user-screening-record'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 绑定筛查记录
 * request: bizUserScreeningRecordBinding
 * formData: bizUserScreeningRecordBindingFormData
 * url: bizUserScreeningRecordBinding_URL
 * method: bizUserScreeningRecordBinding_TYPE
 * raw_url: bizUserScreeningRecordBinding_RAW_URL
 * @param studentId - studentId
 */
export const bizUserScreeningRecordBinding = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/business/user-screening-record/binding'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['studentId'] !== undefined) {
    queryParameters['studentId'] = parameters['studentId']
  }
  if (parameters['studentId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: studentId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const bizUserScreeningRecordBindingFormData = function(formData) {
  const url = bizUserScreeningRecordBinding_RAW_URL()
  const method = bizUserScreeningRecordBinding_TYPE()
  return axios[method](url, formData)
}
export const bizUserScreeningRecordBinding_RAW_URL = function() {
  return '/business/user-screening-record/binding'
}
export const bizUserScreeningRecordBinding_TYPE = function() {
  return 'post'
}
export const bizUserScreeningRecordBinding_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/business/user-screening-record/binding'
  if (parameters['studentId'] !== undefined) {
    queryParameters['studentId'] = parameters['studentId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 根据学生信息绑定筛查记录
 * request: bizUserScreeningRecordBindingByInfo
 * formData: bizUserScreeningRecordBindingByInfoFormData
 * url: bizUserScreeningRecordBindingByInfo_URL
 * method: bizUserScreeningRecordBindingByInfo_TYPE
 * raw_url: bizUserScreeningRecordBindingByInfo_RAW_URL
 * @param idCard - 
 * @param name - 
 */
export const bizUserScreeningRecordBindingByInfo = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/business/user-screening-record/binding/by-info'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['idCard'] !== undefined) {
    form['idCard'] = parameters['idCard']
  }
  if (parameters['idCard'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: idCard'))
  }
  if (parameters['name'] !== undefined) {
    form['name'] = parameters['name']
  }
  if (parameters['name'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: name'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const bizUserScreeningRecordBindingByInfoFormData = function(formData) {
  const url = bizUserScreeningRecordBindingByInfo_RAW_URL()
  const method = bizUserScreeningRecordBindingByInfo_TYPE()
  return axios[method](url, formData)
}
export const bizUserScreeningRecordBindingByInfo_RAW_URL = function() {
  return '/business/user-screening-record/binding/by-info'
}
export const bizUserScreeningRecordBindingByInfo_TYPE = function() {
  return 'post'
}
export const bizUserScreeningRecordBindingByInfo_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/business/user-screening-record/binding/by-info'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 筛查记录详情
 * request: bizUserScreeningRecordDetail
 * formData: bizUserScreeningRecordDetailFormData
 * url: bizUserScreeningRecordDetail_URL
 * method: bizUserScreeningRecordDetail_TYPE
 * raw_url: bizUserScreeningRecordDetail_RAW_URL
 * @param studentId - studentId
 */
export const bizUserScreeningRecordDetail = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/business/user-screening-record/detail/{studentId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{studentId}', `${parameters['studentId']}`)
  if (parameters['studentId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: studentId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const bizUserScreeningRecordDetailFormData = function(formData) {
  const url = bizUserScreeningRecordDetail_RAW_URL()
  const method = bizUserScreeningRecordDetail_TYPE()
  return axios[method](url, formData)
}
export const bizUserScreeningRecordDetail_RAW_URL = function() {
  return '/business/user-screening-record/detail/{studentId}'
}
export const bizUserScreeningRecordDetail_TYPE = function() {
  return 'get'
}
export const bizUserScreeningRecordDetail_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/business/user-screening-record/detail/{studentId}'
  path = path.replace('{studentId}', `${parameters['studentId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 用户历史筛查记录
 * request: bizUserScreeningRecordHistory
 * formData: bizUserScreeningRecordHistoryFormData
 * url: bizUserScreeningRecordHistory_URL
 * method: bizUserScreeningRecordHistory_TYPE
 * raw_url: bizUserScreeningRecordHistory_RAW_URL
 * @param studentId - studentId
 * @param checked - 
 */
export const bizUserScreeningRecordHistory = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/business/user-screening-record/history/{studentId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{studentId}', `${parameters['studentId']}`)
  if (parameters['studentId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: studentId'))
  }
  if (parameters['checked'] !== undefined) {
    queryParameters['checked'] = parameters['checked']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const bizUserScreeningRecordHistoryFormData = function(formData) {
  const url = bizUserScreeningRecordHistory_RAW_URL()
  const method = bizUserScreeningRecordHistory_TYPE()
  return axios[method](url, formData)
}
export const bizUserScreeningRecordHistory_RAW_URL = function() {
  return '/business/user-screening-record/history/{studentId}'
}
export const bizUserScreeningRecordHistory_TYPE = function() {
  return 'get'
}
export const bizUserScreeningRecordHistory_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/business/user-screening-record/history/{studentId}'
  path = path.replace('{studentId}', `${parameters['studentId']}`)
  if (parameters['checked'] !== undefined) {
    queryParameters['checked'] = parameters['checked']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 修改筛查记录学生手机号码
 * request: bizUserScreeningRecordUpdateStudentMobile
 * formData: bizUserScreeningRecordUpdateStudentMobileFormData
 * url: bizUserScreeningRecordUpdateStudentMobile_URL
 * method: bizUserScreeningRecordUpdateStudentMobile_TYPE
 * raw_url: bizUserScreeningRecordUpdateStudentMobile_RAW_URL
 * @param studentId - studentId
 * @param mobile - 
 */
export const bizUserScreeningRecordUpdateStudentMobile = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/business/user-screening-record/update-student-mobile/{studentId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{studentId}', `${parameters['studentId']}`)
  if (parameters['studentId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: studentId'))
  }
  if (parameters['mobile'] !== undefined) {
    form['mobile'] = parameters['mobile']
  }
  if (parameters['mobile'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: mobile'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const bizUserScreeningRecordUpdateStudentMobileFormData = function(formData) {
  const url = bizUserScreeningRecordUpdateStudentMobile_RAW_URL()
  const method = bizUserScreeningRecordUpdateStudentMobile_TYPE()
  return axios[method](url, formData)
}
export const bizUserScreeningRecordUpdateStudentMobile_RAW_URL = function() {
  return '/business/user-screening-record/update-student-mobile/{studentId}'
}
export const bizUserScreeningRecordUpdateStudentMobile_TYPE = function() {
  return 'put'
}
export const bizUserScreeningRecordUpdateStudentMobile_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/business/user-screening-record/update-student-mobile/{studentId}'
  path = path.replace('{studentId}', `${parameters['studentId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 通过Ticket获取Token
 * request: bizUserLoginByTicket
 * formData: bizUserLoginByTicketFormData
 * url: bizUserLoginByTicket_URL
 * method: bizUserLoginByTicket_TYPE
 * raw_url: bizUserLoginByTicket_RAW_URL
 * @param ticket - ticket
 */
export const bizUserLoginByTicket = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/business/user/login-by-ticket'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['ticket'] !== undefined) {
    queryParameters['ticket'] = parameters['ticket']
  }
  if (parameters['ticket'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: ticket'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const bizUserLoginByTicketFormData = function(formData) {
  const url = bizUserLoginByTicket_RAW_URL()
  const method = bizUserLoginByTicket_TYPE()
  return axios[method](url, formData)
}
export const bizUserLoginByTicket_RAW_URL = function() {
  return '/business/user/login-by-ticket'
}
export const bizUserLoginByTicket_TYPE = function() {
  return 'get'
}
export const bizUserLoginByTicket_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/business/user/login-by-ticket'
  if (parameters['ticket'] !== undefined) {
    queryParameters['ticket'] = parameters['ticket']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 微信小程序登录
 * request: bizUserWxAppletsLogin
 * formData: bizUserWxAppletsLoginFormData
 * url: bizUserWxAppletsLogin_URL
 * method: bizUserWxAppletsLogin_TYPE
 * raw_url: bizUserWxAppletsLogin_RAW_URL
 * @param type - 小程序类型
 * @param code - 微信code
 */
export const bizUserWxAppletsLogin = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/business/user/wx-applets-login'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['type'] !== undefined) {
    form['type'] = parameters['type']
  }
  if (parameters['type'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: type'))
  }
  if (parameters['code'] !== undefined) {
    form['code'] = parameters['code']
  }
  if (parameters['code'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: code'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const bizUserWxAppletsLoginFormData = function(formData) {
  const url = bizUserWxAppletsLogin_RAW_URL()
  const method = bizUserWxAppletsLogin_TYPE()
  return axios[method](url, formData)
}
export const bizUserWxAppletsLogin_RAW_URL = function() {
  return '/business/user/wx-applets-login'
}
export const bizUserWxAppletsLogin_TYPE = function() {
  return 'post'
}
export const bizUserWxAppletsLogin_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/business/user/wx-applets-login'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 就诊人列表
 * request: bizUserSitePatientList
 * formData: bizUserSitePatientListFormData
 * url: bizUserSitePatientList_URL
 * method: bizUserSitePatientList_TYPE
 * raw_url: bizUserSitePatientList_RAW_URL
 * @param targetSiteCode - targetSiteCode
 */
export const bizUserSitePatientList = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/business/usp'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['targetSiteCode'] !== undefined) {
    queryParameters['targetSiteCode'] = parameters['targetSiteCode']
  }
  if (parameters['targetSiteCode'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: targetSiteCode'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const bizUserSitePatientListFormData = function(formData) {
  const url = bizUserSitePatientList_RAW_URL()
  const method = bizUserSitePatientList_TYPE()
  return axios[method](url, formData)
}
export const bizUserSitePatientList_RAW_URL = function() {
  return '/business/usp'
}
export const bizUserSitePatientList_TYPE = function() {
  return 'get'
}
export const bizUserSitePatientList_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/business/usp'
  if (parameters['targetSiteCode'] !== undefined) {
    queryParameters['targetSiteCode'] = parameters['targetSiteCode']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 绑定就诊人
 * request: bizUserSitePatientBinding
 * formData: bizUserSitePatientBindingFormData
 * url: bizUserSitePatientBinding_URL
 * method: bizUserSitePatientBinding_TYPE
 * raw_url: bizUserSitePatientBinding_RAW_URL
 * @param targetSiteCode - 
 * @param sitePatientId - 
 * @param name - 
 */
export const bizUserSitePatientBinding = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/business/usp/binding'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['targetSiteCode'] !== undefined) {
    form['targetSiteCode'] = parameters['targetSiteCode']
  }
  if (parameters['targetSiteCode'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: targetSiteCode'))
  }
  if (parameters['sitePatientId'] !== undefined) {
    form['sitePatientId'] = parameters['sitePatientId']
  }
  if (parameters['sitePatientId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: sitePatientId'))
  }
  if (parameters['name'] !== undefined) {
    form['name'] = parameters['name']
  }
  if (parameters['name'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: name'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const bizUserSitePatientBindingFormData = function(formData) {
  const url = bizUserSitePatientBinding_RAW_URL()
  const method = bizUserSitePatientBinding_TYPE()
  return axios[method](url, formData)
}
export const bizUserSitePatientBinding_RAW_URL = function() {
  return '/business/usp/binding'
}
export const bizUserSitePatientBinding_TYPE = function() {
  return 'post'
}
export const bizUserSitePatientBinding_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/business/usp/binding'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 通过手机绑定就诊人
 * request: bizUserSitePatientBindingByMobile
 * formData: bizUserSitePatientBindingByMobileFormData
 * url: bizUserSitePatientBindingByMobile_URL
 * method: bizUserSitePatientBindingByMobile_TYPE
 * raw_url: bizUserSitePatientBindingByMobile_RAW_URL
 * @param targetSiteCode - 
 * @param sitePatientId - 
 * @param mobile - 
 * @param idCard - 
 * @param smsCode - 
 */
export const bizUserSitePatientBindingByMobile = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/business/usp/binding/by-mobile'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['targetSiteCode'] !== undefined) {
    form['targetSiteCode'] = parameters['targetSiteCode']
  }
  if (parameters['targetSiteCode'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: targetSiteCode'))
  }
  if (parameters['sitePatientId'] !== undefined) {
    form['sitePatientId'] = parameters['sitePatientId']
  }
  if (parameters['sitePatientId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: sitePatientId'))
  }
  if (parameters['mobile'] !== undefined) {
    form['mobile'] = parameters['mobile']
  }
  if (parameters['idCard'] !== undefined) {
    form['idCard'] = parameters['idCard']
  }
  if (parameters['smsCode'] !== undefined) {
    form['smsCode'] = parameters['smsCode']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const bizUserSitePatientBindingByMobileFormData = function(formData) {
  const url = bizUserSitePatientBindingByMobile_RAW_URL()
  const method = bizUserSitePatientBindingByMobile_TYPE()
  return axios[method](url, formData)
}
export const bizUserSitePatientBindingByMobile_RAW_URL = function() {
  return '/business/usp/binding/by-mobile'
}
export const bizUserSitePatientBindingByMobile_TYPE = function() {
  return 'post'
}
export const bizUserSitePatientBindingByMobile_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/business/usp/binding/by-mobile'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 根据姓名身份证添加就诊人
 * request: bizUserSitePatientSaveByNameIdCard
 * formData: bizUserSitePatientSaveByNameIdCardFormData
 * url: bizUserSitePatientSaveByNameIdCard_URL
 * method: bizUserSitePatientSaveByNameIdCard_TYPE
 * raw_url: bizUserSitePatientSaveByNameIdCard_RAW_URL
 * @param name - 
 * @param idCard - 
 * @param targetSiteCode - 
 */
export const bizUserSitePatientSaveByNameIdCard = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/business/usp/by-name-id-card'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['name'] !== undefined) {
    form['name'] = parameters['name']
  }
  if (parameters['name'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: name'))
  }
  if (parameters['idCard'] !== undefined) {
    form['idCard'] = parameters['idCard']
  }
  if (parameters['idCard'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: idCard'))
  }
  if (parameters['targetSiteCode'] !== undefined) {
    form['targetSiteCode'] = parameters['targetSiteCode']
  }
  if (parameters['targetSiteCode'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: targetSiteCode'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const bizUserSitePatientSaveByNameIdCardFormData = function(formData) {
  const url = bizUserSitePatientSaveByNameIdCard_RAW_URL()
  const method = bizUserSitePatientSaveByNameIdCard_TYPE()
  return axios[method](url, formData)
}
export const bizUserSitePatientSaveByNameIdCard_RAW_URL = function() {
  return '/business/usp/by-name-id-card'
}
export const bizUserSitePatientSaveByNameIdCard_TYPE = function() {
  return 'post'
}
export const bizUserSitePatientSaveByNameIdCard_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/business/usp/by-name-id-card'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 根据姓名登记号校验患者
 * request: bizUserSitePatientCheckByNameCode
 * formData: bizUserSitePatientCheckByNameCodeFormData
 * url: bizUserSitePatientCheckByNameCode_URL
 * method: bizUserSitePatientCheckByNameCode_TYPE
 * raw_url: bizUserSitePatientCheckByNameCode_RAW_URL
 * @param targetSiteCode - targetSiteCode
 * @param name - name
 * @param code - code
 * @param idCard - idCard
 */
export const bizUserSitePatientCheckByNameCode = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/business/usp/check/by-name-code'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['targetSiteCode'] !== undefined) {
    queryParameters['targetSiteCode'] = parameters['targetSiteCode']
  }
  if (parameters['targetSiteCode'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: targetSiteCode'))
  }
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['name'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: name'))
  }
  if (parameters['code'] !== undefined) {
    queryParameters['code'] = parameters['code']
  }
  if (parameters['code'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: code'))
  }
  if (parameters['idCard'] !== undefined) {
    queryParameters['idCard'] = parameters['idCard']
  }
  if (parameters['idCard'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: idCard'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const bizUserSitePatientCheckByNameCodeFormData = function(formData) {
  const url = bizUserSitePatientCheckByNameCode_RAW_URL()
  const method = bizUserSitePatientCheckByNameCode_TYPE()
  return axios[method](url, formData)
}
export const bizUserSitePatientCheckByNameCode_RAW_URL = function() {
  return '/business/usp/check/by-name-code'
}
export const bizUserSitePatientCheckByNameCode_TYPE = function() {
  return 'get'
}
export const bizUserSitePatientCheckByNameCode_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/business/usp/check/by-name-code'
  if (parameters['targetSiteCode'] !== undefined) {
    queryParameters['targetSiteCode'] = parameters['targetSiteCode']
  }
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['code'] !== undefined) {
    queryParameters['code'] = parameters['code']
  }
  if (parameters['idCard'] !== undefined) {
    queryParameters['idCard'] = parameters['idCard']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 发送短信验证码(绑定患者)
 * request: bizUserSitePatientSendCode
 * formData: bizUserSitePatientSendCodeFormData
 * url: bizUserSitePatientSendCode_URL
 * method: bizUserSitePatientSendCode_TYPE
 * raw_url: bizUserSitePatientSendCode_RAW_URL
 * @param targetSiteCode - targetSiteCode
 * @param sitePatientId - sitePatientId
 * @param mobile - mobile
 * @param captchaId - captchaId
 * @param captcha - captcha
 */
export const bizUserSitePatientSendCode = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/business/usp/send-code'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['targetSiteCode'] !== undefined) {
    queryParameters['targetSiteCode'] = parameters['targetSiteCode']
  }
  if (parameters['targetSiteCode'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: targetSiteCode'))
  }
  if (parameters['sitePatientId'] !== undefined) {
    queryParameters['sitePatientId'] = parameters['sitePatientId']
  }
  if (parameters['sitePatientId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: sitePatientId'))
  }
  if (parameters['mobile'] !== undefined) {
    queryParameters['mobile'] = parameters['mobile']
  }
  if (parameters['captchaId'] !== undefined) {
    queryParameters['captchaId'] = parameters['captchaId']
  }
  if (parameters['captchaId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: captchaId'))
  }
  if (parameters['captcha'] !== undefined) {
    queryParameters['captcha'] = parameters['captcha']
  }
  if (parameters['captcha'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: captcha'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const bizUserSitePatientSendCodeFormData = function(formData) {
  const url = bizUserSitePatientSendCode_RAW_URL()
  const method = bizUserSitePatientSendCode_TYPE()
  return axios[method](url, formData)
}
export const bizUserSitePatientSendCode_RAW_URL = function() {
  return '/business/usp/send-code'
}
export const bizUserSitePatientSendCode_TYPE = function() {
  return 'get'
}
export const bizUserSitePatientSendCode_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/business/usp/send-code'
  if (parameters['targetSiteCode'] !== undefined) {
    queryParameters['targetSiteCode'] = parameters['targetSiteCode']
  }
  if (parameters['sitePatientId'] !== undefined) {
    queryParameters['sitePatientId'] = parameters['sitePatientId']
  }
  if (parameters['mobile'] !== undefined) {
    queryParameters['mobile'] = parameters['mobile']
  }
  if (parameters['captchaId'] !== undefined) {
    queryParameters['captchaId'] = parameters['captchaId']
  }
  if (parameters['captcha'] !== undefined) {
    queryParameters['captcha'] = parameters['captcha']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 我的就诊记录列表
 * request: bizVisitingRecordList
 * formData: bizVisitingRecordListFormData
 * url: bizVisitingRecordList_URL
 * method: bizVisitingRecordList_TYPE
 * raw_url: bizVisitingRecordList_RAW_URL
 * @param targetSiteCode - targetSiteCode
 * @param sitePatientId - 站点患者ID(选全部时不传)
 * @param page - 
 * @param rows - 
 * @param sort - 
 * @param order - 
 */
export const bizVisitingRecordList = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/business/visiting-record'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['targetSiteCode'] !== undefined) {
    queryParameters['targetSiteCode'] = parameters['targetSiteCode']
  }
  if (parameters['targetSiteCode'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: targetSiteCode'))
  }
  if (parameters['sitePatientId'] !== undefined) {
    queryParameters['sitePatientId'] = parameters['sitePatientId']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const bizVisitingRecordListFormData = function(formData) {
  const url = bizVisitingRecordList_RAW_URL()
  const method = bizVisitingRecordList_TYPE()
  return axios[method](url, formData)
}
export const bizVisitingRecordList_RAW_URL = function() {
  return '/business/visiting-record'
}
export const bizVisitingRecordList_TYPE = function() {
  return 'get'
}
export const bizVisitingRecordList_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/business/visiting-record'
  if (parameters['targetSiteCode'] !== undefined) {
    queryParameters['targetSiteCode'] = parameters['targetSiteCode']
  }
  if (parameters['sitePatientId'] !== undefined) {
    queryParameters['sitePatientId'] = parameters['sitePatientId']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 查看就诊记录详情
 * request: bizVisitingRecordFind
 * formData: bizVisitingRecordFindFormData
 * url: bizVisitingRecordFind_URL
 * method: bizVisitingRecordFind_TYPE
 * raw_url: bizVisitingRecordFind_RAW_URL
 * @param targetSiteCode - targetSiteCode
 * @param recordId - recordId
 */
export const bizVisitingRecordFind = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/business/visiting-record/{recordId}'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['targetSiteCode'] !== undefined) {
    queryParameters['targetSiteCode'] = parameters['targetSiteCode']
  }
  if (parameters['targetSiteCode'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: targetSiteCode'))
  }
  path = path.replace('{recordId}', `${parameters['recordId']}`)
  if (parameters['recordId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: recordId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const bizVisitingRecordFindFormData = function(formData) {
  const url = bizVisitingRecordFind_RAW_URL()
  const method = bizVisitingRecordFind_TYPE()
  return axios[method](url, formData)
}
export const bizVisitingRecordFind_RAW_URL = function() {
  return '/business/visiting-record/{recordId}'
}
export const bizVisitingRecordFind_TYPE = function() {
  return 'get'
}
export const bizVisitingRecordFind_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/business/visiting-record/{recordId}'
  if (parameters['targetSiteCode'] !== undefined) {
    queryParameters['targetSiteCode'] = parameters['targetSiteCode']
  }
  path = path.replace('{recordId}', `${parameters['recordId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 班次列表
 * request: bizWorkClassesList
 * formData: bizWorkClassesListFormData
 * url: bizWorkClassesList_URL
 * method: bizWorkClassesList_TYPE
 * raw_url: bizWorkClassesList_RAW_URL
 * @param targetSiteCode - targetSiteCode
 */
export const bizWorkClassesList = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/business/work-classes'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['targetSiteCode'] !== undefined) {
    queryParameters['targetSiteCode'] = parameters['targetSiteCode']
  }
  if (parameters['targetSiteCode'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: targetSiteCode'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const bizWorkClassesListFormData = function(formData) {
  const url = bizWorkClassesList_RAW_URL()
  const method = bizWorkClassesList_TYPE()
  return axios[method](url, formData)
}
export const bizWorkClassesList_RAW_URL = function() {
  return '/business/work-classes'
}
export const bizWorkClassesList_TYPE = function() {
  return 'get'
}
export const bizWorkClassesList_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/business/work-classes'
  if (parameters['targetSiteCode'] !== undefined) {
    queryParameters['targetSiteCode'] = parameters['targetSiteCode']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 排班列表
 * request: bizWorkSchedulingList
 * formData: bizWorkSchedulingListFormData
 * url: bizWorkSchedulingList_URL
 * method: bizWorkSchedulingList_TYPE
 * raw_url: bizWorkSchedulingList_RAW_URL
 * @param deptId - deptId
 * @param targetSiteCode - 所选医院站点编码
 * @param date - 日期(yyyy-MM-dd)转成的时间戳，不要时分秒毫秒
 */
export const bizWorkSchedulingList = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/business/work-scheduling/by-department/{deptId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{deptId}', `${parameters['deptId']}`)
  if (parameters['deptId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: deptId'))
  }
  if (parameters['targetSiteCode'] !== undefined) {
    queryParameters['targetSiteCode'] = parameters['targetSiteCode']
  }
  if (parameters['targetSiteCode'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: targetSiteCode'))
  }
  if (parameters['date'] !== undefined) {
    queryParameters['date'] = parameters['date']
  }
  if (parameters['date'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: date'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const bizWorkSchedulingListFormData = function(formData) {
  const url = bizWorkSchedulingList_RAW_URL()
  const method = bizWorkSchedulingList_TYPE()
  return axios[method](url, formData)
}
export const bizWorkSchedulingList_RAW_URL = function() {
  return '/business/work-scheduling/by-department/{deptId}'
}
export const bizWorkSchedulingList_TYPE = function() {
  return 'get'
}
export const bizWorkSchedulingList_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/business/work-scheduling/by-department/{deptId}'
  path = path.replace('{deptId}', `${parameters['deptId']}`)
  if (parameters['targetSiteCode'] !== undefined) {
    queryParameters['targetSiteCode'] = parameters['targetSiteCode']
  }
  if (parameters['date'] !== undefined) {
    queryParameters['date'] = parameters['date']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 排班详情
 * request: bizWorkSchedulingFind
 * formData: bizWorkSchedulingFindFormData
 * url: bizWorkSchedulingFind_URL
 * method: bizWorkSchedulingFind_TYPE
 * raw_url: bizWorkSchedulingFind_RAW_URL
 * @param id - id
 * @param targetSiteCode - 所选医院站点编码
 */
export const bizWorkSchedulingFind = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/business/work-scheduling/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['targetSiteCode'] !== undefined) {
    queryParameters['targetSiteCode'] = parameters['targetSiteCode']
  }
  if (parameters['targetSiteCode'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: targetSiteCode'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const bizWorkSchedulingFindFormData = function(formData) {
  const url = bizWorkSchedulingFind_RAW_URL()
  const method = bizWorkSchedulingFind_TYPE()
  return axios[method](url, formData)
}
export const bizWorkSchedulingFind_RAW_URL = function() {
  return '/business/work-scheduling/{id}'
}
export const bizWorkSchedulingFind_TYPE = function() {
  return 'get'
}
export const bizWorkSchedulingFind_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/business/work-scheduling/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['targetSiteCode'] !== undefined) {
    queryParameters['targetSiteCode'] = parameters['targetSiteCode']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 排班预约数详情
 * request: bizWorkSchedulingAppointmentNumDetail
 * formData: bizWorkSchedulingAppointmentNumDetailFormData
 * url: bizWorkSchedulingAppointmentNumDetail_URL
 * method: bizWorkSchedulingAppointmentNumDetail_TYPE
 * raw_url: bizWorkSchedulingAppointmentNumDetail_RAW_URL
 * @param id - id
 * @param targetSiteCode - 所选医院站点编码
 */
export const bizWorkSchedulingAppointmentNumDetail = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/business/work-scheduling/{id}/appointment-num/detail'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['targetSiteCode'] !== undefined) {
    queryParameters['targetSiteCode'] = parameters['targetSiteCode']
  }
  if (parameters['targetSiteCode'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: targetSiteCode'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const bizWorkSchedulingAppointmentNumDetailFormData = function(formData) {
  const url = bizWorkSchedulingAppointmentNumDetail_RAW_URL()
  const method = bizWorkSchedulingAppointmentNumDetail_TYPE()
  return axios[method](url, formData)
}
export const bizWorkSchedulingAppointmentNumDetail_RAW_URL = function() {
  return '/business/work-scheduling/{id}/appointment-num/detail'
}
export const bizWorkSchedulingAppointmentNumDetail_TYPE = function() {
  return 'get'
}
export const bizWorkSchedulingAppointmentNumDetail_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/business/work-scheduling/{id}/appointment-num/detail'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['targetSiteCode'] !== undefined) {
    queryParameters['targetSiteCode'] = parameters['targetSiteCode']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 科普轮播图列表
 * request: BizWxAdvertList
 * formData: BizWxAdvertListFormData
 * url: BizWxAdvertList_URL
 * method: BizWxAdvertList_TYPE
 * raw_url: BizWxAdvertList_RAW_URL
 */
export const BizWxAdvertList = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/business/wx_advert'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const BizWxAdvertListFormData = function(formData) {
  const url = BizWxAdvertList_RAW_URL()
  const method = BizWxAdvertList_TYPE()
  return axios[method](url, formData)
}
export const BizWxAdvertList_RAW_URL = function() {
  return '/business/wx_advert'
}
export const BizWxAdvertList_TYPE = function() {
  return 'get'
}
export const BizWxAdvertList_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/business/wx_advert'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 获取SiteCode
 * request: bizWxAppletGetSiteCode
 * formData: bizWxAppletGetSiteCodeFormData
 * url: bizWxAppletGetSiteCode_URL
 * method: bizWxAppletGetSiteCode_TYPE
 * raw_url: bizWxAppletGetSiteCode_RAW_URL
 * @param type - 类型1视力自测小程序2建档
 * @param appId - appId
 */
export const bizWxAppletGetSiteCode = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/business/wx_applet'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['type'] !== undefined) {
    queryParameters['type'] = parameters['type']
  }
  if (parameters['type'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: type'))
  }
  if (parameters['appId'] !== undefined) {
    queryParameters['appId'] = parameters['appId']
  }
  if (parameters['appId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: appId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const bizWxAppletGetSiteCodeFormData = function(formData) {
  const url = bizWxAppletGetSiteCode_RAW_URL()
  const method = bizWxAppletGetSiteCode_TYPE()
  return axios[method](url, formData)
}
export const bizWxAppletGetSiteCode_RAW_URL = function() {
  return '/business/wx_applet'
}
export const bizWxAppletGetSiteCode_TYPE = function() {
  return 'get'
}
export const bizWxAppletGetSiteCode_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/business/wx_applet'
  if (parameters['type'] !== undefined) {
    queryParameters['type'] = parameters['type']
  }
  if (parameters['appId'] !== undefined) {
    queryParameters['appId'] = parameters['appId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 测试用户列表
 * request: bizWxAppletsTesterList
 * formData: bizWxAppletsTesterListFormData
 * url: bizWxAppletsTesterList_URL
 * method: bizWxAppletsTesterList_TYPE
 * raw_url: bizWxAppletsTesterList_RAW_URL
 */
export const bizWxAppletsTesterList = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/business/wx_applets_tester'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const bizWxAppletsTesterListFormData = function(formData) {
  const url = bizWxAppletsTesterList_RAW_URL()
  const method = bizWxAppletsTesterList_TYPE()
  return axios[method](url, formData)
}
export const bizWxAppletsTesterList_RAW_URL = function() {
  return '/business/wx_applets_tester'
}
export const bizWxAppletsTesterList_TYPE = function() {
  return 'get'
}
export const bizWxAppletsTesterList_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/business/wx_applets_tester'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 新增测试用户
 * request: bizWxAppletsTesterAdd
 * formData: bizWxAppletsTesterAddFormData
 * url: bizWxAppletsTesterAdd_URL
 * method: bizWxAppletsTesterAdd_TYPE
 * raw_url: bizWxAppletsTesterAdd_RAW_URL
 * @param id - id(修改必传)
 * @param name - 姓名
 * @param sex - 性别
 * @param birthday - 生日
 * @param schoolName - 学校名称
 * @param className - 班级名称
 * @param grade - 年级
 */
export const bizWxAppletsTesterAdd = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/business/wx_applets_tester'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['id'] !== undefined) {
    form['id'] = parameters['id']
  }
  if (parameters['name'] !== undefined) {
    form['name'] = parameters['name']
  }
  if (parameters['name'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: name'))
  }
  if (parameters['sex'] !== undefined) {
    form['sex'] = parameters['sex']
  }
  if (parameters['sex'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: sex'))
  }
  if (parameters['birthday'] !== undefined) {
    form['birthday'] = parameters['birthday']
  }
  if (parameters['birthday'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: birthday'))
  }
  if (parameters['schoolName'] !== undefined) {
    form['schoolName'] = parameters['schoolName']
  }
  if (parameters['schoolName'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: schoolName'))
  }
  if (parameters['className'] !== undefined) {
    form['className'] = parameters['className']
  }
  if (parameters['className'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: className'))
  }
  if (parameters['grade'] !== undefined) {
    form['grade'] = parameters['grade']
  }
  if (parameters['grade'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: grade'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const bizWxAppletsTesterAddFormData = function(formData) {
  const url = bizWxAppletsTesterAdd_RAW_URL()
  const method = bizWxAppletsTesterAdd_TYPE()
  return axios[method](url, formData)
}
export const bizWxAppletsTesterAdd_RAW_URL = function() {
  return '/business/wx_applets_tester'
}
export const bizWxAppletsTesterAdd_TYPE = function() {
  return 'post'
}
export const bizWxAppletsTesterAdd_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/business/wx_applets_tester'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 测试用户详情
 * request: bizWxAppletsTesterDetails
 * formData: bizWxAppletsTesterDetailsFormData
 * url: bizWxAppletsTesterDetails_URL
 * method: bizWxAppletsTesterDetails_TYPE
 * raw_url: bizWxAppletsTesterDetails_RAW_URL
 * @param id - id
 */
export const bizWxAppletsTesterDetails = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/business/wx_applets_tester/{id}/details'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const bizWxAppletsTesterDetailsFormData = function(formData) {
  const url = bizWxAppletsTesterDetails_RAW_URL()
  const method = bizWxAppletsTesterDetails_TYPE()
  return axios[method](url, formData)
}
export const bizWxAppletsTesterDetails_RAW_URL = function() {
  return '/business/wx_applets_tester/{id}/details'
}
export const bizWxAppletsTesterDetails_TYPE = function() {
  return 'get'
}
export const bizWxAppletsTesterDetails_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/business/wx_applets_tester/{id}/details'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 新增自测数据
 * request: bizWxAppletsVisionReportAdd
 * formData: bizWxAppletsVisionReportAddFormData
 * url: bizWxAppletsVisionReportAdd_URL
 * method: bizWxAppletsVisionReportAdd_TYPE
 * raw_url: bizWxAppletsVisionReportAdd_RAW_URL
 * @param userLoginName - 用户唯一标识
 * @param testerId - 检测用户
 * @param distance - 距离
 * @param brightness - 检测亮度
 * @param type - 检测类型
 * @param leftVision - 左眼
 * @param rightVision - 右眼
 */
export const bizWxAppletsVisionReportAdd = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/business/wx_applets_vision_report'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['userLoginName'] !== undefined) {
    form['userLoginName'] = parameters['userLoginName']
  }
  if (parameters['userLoginName'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: userLoginName'))
  }
  if (parameters['testerId'] !== undefined) {
    form['testerId'] = parameters['testerId']
  }
  if (parameters['testerId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: testerId'))
  }
  if (parameters['distance'] !== undefined) {
    form['distance'] = parameters['distance']
  }
  if (parameters['distance'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: distance'))
  }
  if (parameters['brightness'] !== undefined) {
    form['brightness'] = parameters['brightness']
  }
  if (parameters['brightness'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: brightness'))
  }
  if (parameters['type'] !== undefined) {
    form['type'] = parameters['type']
  }
  if (parameters['type'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: type'))
  }
  if (parameters['leftVision'] !== undefined) {
    form['leftVision'] = parameters['leftVision']
  }
  if (parameters['leftVision'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: leftVision'))
  }
  if (parameters['rightVision'] !== undefined) {
    form['rightVision'] = parameters['rightVision']
  }
  if (parameters['rightVision'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: rightVision'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const bizWxAppletsVisionReportAddFormData = function(formData) {
  const url = bizWxAppletsVisionReportAdd_RAW_URL()
  const method = bizWxAppletsVisionReportAdd_TYPE()
  return axios[method](url, formData)
}
export const bizWxAppletsVisionReportAdd_RAW_URL = function() {
  return '/business/wx_applets_vision_report'
}
export const bizWxAppletsVisionReportAdd_TYPE = function() {
  return 'post'
}
export const bizWxAppletsVisionReportAdd_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/business/wx_applets_vision_report'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 自测数据详情
 * request: bizWxAppletsVisionReportDetails
 * formData: bizWxAppletsVisionReportDetailsFormData
 * url: bizWxAppletsVisionReportDetails_URL
 * method: bizWxAppletsVisionReportDetails_TYPE
 * raw_url: bizWxAppletsVisionReportDetails_RAW_URL
 * @param id - id
 */
export const bizWxAppletsVisionReportDetails = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/business/wx_applets_vision_report/{id}/details'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const bizWxAppletsVisionReportDetailsFormData = function(formData) {
  const url = bizWxAppletsVisionReportDetails_RAW_URL()
  const method = bizWxAppletsVisionReportDetails_TYPE()
  return axios[method](url, formData)
}
export const bizWxAppletsVisionReportDetails_RAW_URL = function() {
  return '/business/wx_applets_vision_report/{id}/details'
}
export const bizWxAppletsVisionReportDetails_TYPE = function() {
  return 'get'
}
export const bizWxAppletsVisionReportDetails_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/business/wx_applets_vision_report/{id}/details'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 自测用户数据列表
 * request: bizWxAppletsVisionReportList
 * formData: bizWxAppletsVisionReportListFormData
 * url: bizWxAppletsVisionReportList_URL
 * method: bizWxAppletsVisionReportList_TYPE
 * raw_url: bizWxAppletsVisionReportList_RAW_URL
 * @param testerId - testerId
 */
export const bizWxAppletsVisionReportList = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/business/wx_applets_vision_report/{testerId}/list'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{testerId}', `${parameters['testerId']}`)
  if (parameters['testerId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: testerId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const bizWxAppletsVisionReportListFormData = function(formData) {
  const url = bizWxAppletsVisionReportList_RAW_URL()
  const method = bizWxAppletsVisionReportList_TYPE()
  return axios[method](url, formData)
}
export const bizWxAppletsVisionReportList_RAW_URL = function() {
  return '/business/wx_applets_vision_report/{testerId}/list'
}
export const bizWxAppletsVisionReportList_TYPE = function() {
  return 'get'
}
export const bizWxAppletsVisionReportList_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/business/wx_applets_vision_report/{testerId}/list'
  path = path.replace('{testerId}', `${parameters['testerId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 可预约地址
 * request: cmAppointmentAddressList
 * formData: cmAppointmentAddressListFormData
 * url: cmAppointmentAddressList_URL
 * method: cmAppointmentAddressList_TYPE
 * raw_url: cmAppointmentAddressList_RAW_URL
 * @param screeningPlanId - screeningPlanId
 */
export const cmAppointmentAddressList = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/appointment/address'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['screeningPlanId'] !== undefined) {
    queryParameters['screeningPlanId'] = parameters['screeningPlanId']
  }
  if (parameters['screeningPlanId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningPlanId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmAppointmentAddressListFormData = function(formData) {
  const url = cmAppointmentAddressList_RAW_URL()
  const method = cmAppointmentAddressList_TYPE()
  return axios[method](url, formData)
}
export const cmAppointmentAddressList_RAW_URL = function() {
  return '/common/appointment/address'
}
export const cmAppointmentAddressList_TYPE = function() {
  return 'get'
}
export const cmAppointmentAddressList_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/appointment/address'
  if (parameters['screeningPlanId'] !== undefined) {
    queryParameters['screeningPlanId'] = parameters['screeningPlanId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 获取captchaid
 * request: cmMainCaptchaid
 * formData: cmMainCaptchaidFormData
 * url: cmMainCaptchaid_URL
 * method: cmMainCaptchaid_TYPE
 * raw_url: cmMainCaptchaid_RAW_URL
 */
export const cmMainCaptchaid = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/main/captchaid'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmMainCaptchaidFormData = function(formData) {
  const url = cmMainCaptchaid_RAW_URL()
  const method = cmMainCaptchaid_TYPE()
  return axios[method](url, formData)
}
export const cmMainCaptchaid_RAW_URL = function() {
  return '/common/main/captchaid'
}
export const cmMainCaptchaid_TYPE = function() {
  return 'get'
}
export const cmMainCaptchaid_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/main/captchaid'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Hello
 * request: cmMainHello
 * formData: cmMainHelloFormData
 * url: cmMainHello_URL
 * method: cmMainHello_TYPE
 * raw_url: cmMainHello_RAW_URL
 */
export const cmMainHello = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/main/hello'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmMainHelloFormData = function(formData) {
  const url = cmMainHello_RAW_URL()
  const method = cmMainHello_TYPE()
  return axios[method](url, formData)
}
export const cmMainHello_RAW_URL = function() {
  return '/common/main/hello'
}
export const cmMainHello_TYPE = function() {
  return 'get'
}
export const cmMainHello_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/main/hello'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 修改密码
 * request: cmMainUpdatePwd
 * formData: cmMainUpdatePwdFormData
 * url: cmMainUpdatePwd_URL
 * method: cmMainUpdatePwd_TYPE
 * raw_url: cmMainUpdatePwd_RAW_URL
 * @param oldPwd - 
 * @param newPwd - 
 */
export const cmMainUpdatePwd = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/main/password'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['oldPwd'] !== undefined) {
    form['oldPwd'] = parameters['oldPwd']
  }
  if (parameters['oldPwd'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: oldPwd'))
  }
  if (parameters['newPwd'] !== undefined) {
    form['newPwd'] = parameters['newPwd']
  }
  if (parameters['newPwd'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: newPwd'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const cmMainUpdatePwdFormData = function(formData) {
  const url = cmMainUpdatePwd_RAW_URL()
  const method = cmMainUpdatePwd_TYPE()
  return axios[method](url, formData)
}
export const cmMainUpdatePwd_RAW_URL = function() {
  return '/common/main/password'
}
export const cmMainUpdatePwd_TYPE = function() {
  return 'put'
}
export const cmMainUpdatePwd_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/main/password'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 系统信息
 * request: cmMainSystemInfo
 * formData: cmMainSystemInfoFormData
 * url: cmMainSystemInfo_URL
 * method: cmMainSystemInfo_TYPE
 * raw_url: cmMainSystemInfo_RAW_URL
 */
export const cmMainSystemInfo = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/main/system-info'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmMainSystemInfoFormData = function(formData) {
  const url = cmMainSystemInfo_RAW_URL()
  const method = cmMainSystemInfo_TYPE()
  return axios[method](url, formData)
}
export const cmMainSystemInfo_RAW_URL = function() {
  return '/common/main/system-info'
}
export const cmMainSystemInfo_TYPE = function() {
  return 'get'
}
export const cmMainSystemInfo_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/main/system-info'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 用户信息
 * request: cmMainUserInfo
 * formData: cmMainUserInfoFormData
 * url: cmMainUserInfo_URL
 * method: cmMainUserInfo_TYPE
 * raw_url: cmMainUserInfo_RAW_URL
 */
export const cmMainUserInfo = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/main/user-info'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmMainUserInfoFormData = function(formData) {
  const url = cmMainUserInfo_RAW_URL()
  const method = cmMainUserInfo_TYPE()
  return axios[method](url, formData)
}
export const cmMainUserInfo_RAW_URL = function() {
  return '/common/main/user-info'
}
export const cmMainUserInfo_TYPE = function() {
  return 'get'
}
export const cmMainUserInfo_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/main/user-info'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 添加筛查学生
 * request: cmScreeningPlanAddStudent
 * formData: cmScreeningPlanAddStudentFormData
 * url: cmScreeningPlanAddStudent_URL
 * method: cmScreeningPlanAddStudent_TYPE
 * raw_url: cmScreeningPlanAddStudent_RAW_URL
 * @param screeningPlanId - 
 * @param schoolId - 
 * @param gradeValue - 
 * @param classValue - 
 * @param name - 
 * @param sex - 
 * @param birth - 
 * @param code - 
 * @param idCard - 
 * @param mobile - 
 * @param nation - 
 */
export const cmScreeningPlanAddStudent = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/screening/add-student'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['screeningPlanId'] !== undefined) {
    form['screeningPlanId'] = parameters['screeningPlanId']
  }
  if (parameters['screeningPlanId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningPlanId'))
  }
  if (parameters['schoolId'] !== undefined) {
    form['schoolId'] = parameters['schoolId']
  }
  if (parameters['schoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: schoolId'))
  }
  if (parameters['gradeValue'] !== undefined) {
    form['gradeValue'] = parameters['gradeValue']
  }
  if (parameters['gradeValue'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: gradeValue'))
  }
  if (parameters['classValue'] !== undefined) {
    form['classValue'] = parameters['classValue']
  }
  if (parameters['classValue'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: classValue'))
  }
  if (parameters['name'] !== undefined) {
    form['name'] = parameters['name']
  }
  if (parameters['name'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: name'))
  }
  if (parameters['sex'] !== undefined) {
    form['sex'] = parameters['sex']
  }
  if (parameters['sex'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: sex'))
  }
  if (parameters['birth'] !== undefined) {
    form['birth'] = parameters['birth']
  }
  if (parameters['birth'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: birth'))
  }
  if (parameters['code'] !== undefined) {
    form['code'] = parameters['code']
  }
  if (parameters['idCard'] !== undefined) {
    form['idCard'] = parameters['idCard']
  }
  if (parameters['mobile'] !== undefined) {
    form['mobile'] = parameters['mobile']
  }
  if (parameters['nation'] !== undefined) {
    form['nation'] = parameters['nation']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const cmScreeningPlanAddStudentFormData = function(formData) {
  const url = cmScreeningPlanAddStudent_RAW_URL()
  const method = cmScreeningPlanAddStudent_TYPE()
  return axios[method](url, formData)
}
export const cmScreeningPlanAddStudent_RAW_URL = function() {
  return '/common/screening/add-student'
}
export const cmScreeningPlanAddStudent_TYPE = function() {
  return 'put'
}
export const cmScreeningPlanAddStudent_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/screening/add-student'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 年级班级列表
 * request: cmScreeningListGradeClassConfig
 * formData: cmScreeningListGradeClassConfigFormData
 * url: cmScreeningListGradeClassConfig_URL
 * method: cmScreeningListGradeClassConfig_TYPE
 * raw_url: cmScreeningListGradeClassConfig_RAW_URL
 * @param type - type
 */
export const cmScreeningListGradeClassConfig = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/screening/screening-plan/grade-class/{type}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{type}', `${parameters['type']}`)
  if (parameters['type'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: type'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmScreeningListGradeClassConfigFormData = function(formData) {
  const url = cmScreeningListGradeClassConfig_RAW_URL()
  const method = cmScreeningListGradeClassConfig_TYPE()
  return axios[method](url, formData)
}
export const cmScreeningListGradeClassConfig_RAW_URL = function() {
  return '/common/screening/screening-plan/grade-class/{type}'
}
export const cmScreeningListGradeClassConfig_TYPE = function() {
  return 'get'
}
export const cmScreeningListGradeClassConfig_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/screening/screening-plan/grade-class/{type}'
  path = path.replace('{type}', `${parameters['type']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 保存知情同意书
 * request: cmScreeningSaveInformedConsentBook
 * formData: cmScreeningSaveInformedConsentBookFormData
 * url: cmScreeningSaveInformedConsentBook_URL
 * method: cmScreeningSaveInformedConsentBook_TYPE
 * raw_url: cmScreeningSaveInformedConsentBook_RAW_URL
 * @param screeningPlanId - 
 * @param schoolId - 
 * @param gradeValue - 
 * @param classValue - 
 * @param studentName - 学生姓名
 * @param birth - 出生日期
 * @param status - 1同意,2不同意
 * @param phone - 联系电话
 * @param file - 
 */
export const cmScreeningSaveInformedConsentBook = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/screening/screening-plan/informed-consent-book'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['screeningPlanId'] !== undefined) {
    queryParameters['screeningPlanId'] = parameters['screeningPlanId']
  }
  if (parameters['screeningPlanId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningPlanId'))
  }
  if (parameters['schoolId'] !== undefined) {
    queryParameters['schoolId'] = parameters['schoolId']
  }
  if (parameters['schoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: schoolId'))
  }
  if (parameters['gradeValue'] !== undefined) {
    queryParameters['gradeValue'] = parameters['gradeValue']
  }
  if (parameters['gradeValue'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: gradeValue'))
  }
  if (parameters['classValue'] !== undefined) {
    queryParameters['classValue'] = parameters['classValue']
  }
  if (parameters['classValue'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: classValue'))
  }
  if (parameters['studentName'] !== undefined) {
    queryParameters['studentName'] = parameters['studentName']
  }
  if (parameters['studentName'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: studentName'))
  }
  if (parameters['birth'] !== undefined) {
    queryParameters['birth'] = parameters['birth']
  }
  if (parameters['birth'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: birth'))
  }
  if (parameters['status'] !== undefined) {
    queryParameters['status'] = parameters['status']
  }
  if (parameters['status'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: status'))
  }
  if (parameters['phone'] !== undefined) {
    queryParameters['phone'] = parameters['phone']
  }
  if (parameters['phone'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: phone'))
  }
  if (parameters['file'] !== undefined) {
    body = parameters['file']
  }
  if (parameters['file'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: file'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const cmScreeningSaveInformedConsentBookFormData = function(formData) {
  const url = cmScreeningSaveInformedConsentBook_RAW_URL()
  const method = cmScreeningSaveInformedConsentBook_TYPE()
  return axios[method](url, formData)
}
export const cmScreeningSaveInformedConsentBook_RAW_URL = function() {
  return '/common/screening/screening-plan/informed-consent-book'
}
export const cmScreeningSaveInformedConsentBook_TYPE = function() {
  return 'post'
}
export const cmScreeningSaveInformedConsentBook_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/screening/screening-plan/informed-consent-book'
  if (parameters['screeningPlanId'] !== undefined) {
    queryParameters['screeningPlanId'] = parameters['screeningPlanId']
  }
  if (parameters['schoolId'] !== undefined) {
    queryParameters['schoolId'] = parameters['schoolId']
  }
  if (parameters['gradeValue'] !== undefined) {
    queryParameters['gradeValue'] = parameters['gradeValue']
  }
  if (parameters['classValue'] !== undefined) {
    queryParameters['classValue'] = parameters['classValue']
  }
  if (parameters['studentName'] !== undefined) {
    queryParameters['studentName'] = parameters['studentName']
  }
  if (parameters['birth'] !== undefined) {
    queryParameters['birth'] = parameters['birth']
  }
  if (parameters['status'] !== undefined) {
    queryParameters['status'] = parameters['status']
  }
  if (parameters['phone'] !== undefined) {
    queryParameters['phone'] = parameters['phone']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 获取筛查计划的学校列表
 * request: cmScreeningListPlanSchool
 * formData: cmScreeningListPlanSchoolFormData
 * url: cmScreeningListPlanSchool_URL
 * method: cmScreeningListPlanSchool_TYPE
 * raw_url: cmScreeningListPlanSchool_RAW_URL
 * @param planId - planId
 */
export const cmScreeningListPlanSchool = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/screening/screening-plan/{planId}/school'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{planId}', `${parameters['planId']}`)
  if (parameters['planId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: planId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmScreeningListPlanSchoolFormData = function(formData) {
  const url = cmScreeningListPlanSchool_RAW_URL()
  const method = cmScreeningListPlanSchool_TYPE()
  return axios[method](url, formData)
}
export const cmScreeningListPlanSchool_RAW_URL = function() {
  return '/common/screening/screening-plan/{planId}/school'
}
export const cmScreeningListPlanSchool_TYPE = function() {
  return 'get'
}
export const cmScreeningListPlanSchool_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/screening/screening-plan/{planId}/school'
  path = path.replace('{planId}', `${parameters['planId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 学校参与筛查的年级班级列表(已经存在学生的班级年级)
 * request: cmScreeningListGradeClassConfigBySchool
 * formData: cmScreeningListGradeClassConfigBySchoolFormData
 * url: cmScreeningListGradeClassConfigBySchool_URL
 * method: cmScreeningListGradeClassConfigBySchool_TYPE
 * raw_url: cmScreeningListGradeClassConfigBySchool_RAW_URL
 * @param planId - planId
 * @param schoolId - schoolId
 */
export const cmScreeningListGradeClassConfigBySchool = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/screening/screening-plan/{planId}/school/{schoolId}/grade-class'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{planId}', `${parameters['planId']}`)
  if (parameters['planId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: planId'))
  }
  path = path.replace('{schoolId}', `${parameters['schoolId']}`)
  if (parameters['schoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: schoolId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmScreeningListGradeClassConfigBySchoolFormData = function(formData) {
  const url = cmScreeningListGradeClassConfigBySchool_RAW_URL()
  const method = cmScreeningListGradeClassConfigBySchool_TYPE()
  return axios[method](url, formData)
}
export const cmScreeningListGradeClassConfigBySchool_RAW_URL = function() {
  return '/common/screening/screening-plan/{planId}/school/{schoolId}/grade-class'
}
export const cmScreeningListGradeClassConfigBySchool_TYPE = function() {
  return 'get'
}
export const cmScreeningListGradeClassConfigBySchool_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/screening/screening-plan/{planId}/school/{schoolId}/grade-class'
  path = path.replace('{planId}', `${parameters['planId']}`)
  path = path.replace('{schoolId}', `${parameters['schoolId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 获取站点oss访问权限
 * request: cmScreeningStsOSSFullAccess
 * formData: cmScreeningStsOSSFullAccessFormData
 * url: cmScreeningStsOSSFullAccess_URL
 * method: cmScreeningStsOSSFullAccess_TYPE
 * raw_url: cmScreeningStsOSSFullAccess_RAW_URL
 */
export const cmScreeningStsOSSFullAccess = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/screening/site-sts-oss-full-access'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmScreeningStsOSSFullAccessFormData = function(formData) {
  const url = cmScreeningStsOSSFullAccess_RAW_URL()
  const method = cmScreeningStsOSSFullAccess_TYPE()
  return axios[method](url, formData)
}
export const cmScreeningStsOSSFullAccess_RAW_URL = function() {
  return '/common/screening/site-sts-oss-full-access'
}
export const cmScreeningStsOSSFullAccess_TYPE = function() {
  return 'get'
}
export const cmScreeningStsOSSFullAccess_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/screening/site-sts-oss-full-access'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 筛查记录详情
 * request: cmUserScreeningRecordDetail
 * formData: cmUserScreeningRecordDetailFormData
 * url: cmUserScreeningRecordDetail_URL
 * method: cmUserScreeningRecordDetail_TYPE
 * raw_url: cmUserScreeningRecordDetail_RAW_URL
 * @param studentId - studentId
 */
export const cmUserScreeningRecordDetail = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/user-screening-record/detail/{studentId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{studentId}', `${parameters['studentId']}`)
  if (parameters['studentId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: studentId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmUserScreeningRecordDetailFormData = function(formData) {
  const url = cmUserScreeningRecordDetail_RAW_URL()
  const method = cmUserScreeningRecordDetail_TYPE()
  return axios[method](url, formData)
}
export const cmUserScreeningRecordDetail_RAW_URL = function() {
  return '/common/user-screening-record/detail/{studentId}'
}
export const cmUserScreeningRecordDetail_TYPE = function() {
  return 'get'
}
export const cmUserScreeningRecordDetail_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/user-screening-record/detail/{studentId}'
  path = path.replace('{studentId}', `${parameters['studentId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}